import React from 'react';
import { DialogTitle, DialogContent, DialogContentText, Box, Grid } from "@mui/material"
import DuplicateRingCard from '../../Card/ConflictCard/DuplicateRingCard';
import FatalErrorCard from '../../Card/ConflictCard/FatalErrorCard';

const ConflictDisplay = ({ conflictsData }) => {
    return (
        <>
            <DialogTitle>Conflicts in merged data</DialogTitle>
            <DialogContent>
                <DialogContentText>Resolve the follow conflicts</DialogContentText>
                <Box>
                    <Grid container style={{width : "100%"}} spacing={1}>
                    { conflictsData.map((e, index) => {

                        if (e.type === 'DupliRingNo') {
                            return (<DuplicateRingCard object={e} /> )
                        } else {
                            return (<FatalErrorCard object={e} />)
                        }
                    }) }
                    </Grid>
                </Box>
            </DialogContent>
        </>
    )
}

export default ConflictDisplay