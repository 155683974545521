const speciesValidator = (param) => {
    let regex = /^[a-zA-Z ]+$/;
    return !regex.test(param);
}

const sexValidator = (param) => {
    let sexVal = param
    if (
        sexVal === "Male" ||
        sexVal === "" ||
        sexVal === "Female" ||
        sexVal === "Unknown"
    ) {
        return true
    }
    return false;
}

const ageValidator = (param) => {
    if ( 
        param === "AD" ||
        param === "IMM" ||
        param === "JUV" ||
        param === "FG" ||
        param === "FW" ||
        param === "1Y" ||
        param === "" ||
        param === " "
    ){
        return true
    }
    const agePattern = new RegExp('^[0-9. ]+$');
    return agePattern.test(param);
}

const dateValidator = (param) => {
    const datePattern = new RegExp('^(0[1-9]|[1-2][0-9]|3[0-1])-([a-zA-Z]{3})-\\d{4}$');
    return !datePattern.test(param);
}

const weightValidator = (param) => {
    const weightPattern = new RegExp('^[0-9.]+$');
    return !weightPattern.test(param);
}

const wingValidator = (param) => {
    const wingValidator = new RegExp('^[0-9.]+$');
    return !wingValidator.test(param);
}

const ringValidator = (param) => {
    const ringPatten = /^[0-9\b]+$/;
    return ringPatten.test(param);
}

const speciesCodeValidator = (param) => {
    const speciesCodePattern = /^[0-9]{1,4}-[0-9]{1}$/;
    return !speciesCodePattern.test(param);
}

const localityValidator = (param) =>{
    return (''+param).trim().length === 0;
}

const moultValidator = (param) => {
    const moultPattern = new RegExp('^[0-9.]+$');
    return !moultPattern.test(param);
}

const plumageValidator = (param) => {
    const plumagePattern = new RegExp('^[0-9.]+$');
    return !plumagePattern.test(param);
}

const broodValidator = (param) => {
    const broodPattern = new RegExp('^[0-9.]+$');
    return !broodPattern.test(param);
}

const billValidator = (param) => {
    const billPattern = new RegExp('^[0-9.]+$');
    return !billPattern.test(param);
}

const tarsValidator = (param) => {
    const tarsPattern = new RegExp('^[0-9.]+$');
    return !tarsPattern.test(param);
}

const tailValidator = (param) => {
    const tailValidator = new RegExp('^[0-9.]+$');
    return !tailValidator.test(param);
}


export { speciesValidator, sexValidator, dateValidator, weightValidator, wingValidator, ringValidator, speciesCodeValidator, ageValidator, localityValidator, moultValidator, plumageValidator, broodValidator, billValidator, tarsValidator, tailValidator }
