import { styled } from '@mui/material/styles';
import { Tab } from "@mui/material"
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontFamily: "'Lato', sans-serif" ,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'var(--font-color-primary)',
    '&:hover': {
      color: 'var(--font-color-secondary)',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: 'var(--font-color-secondary)',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'var(--font-color-secondary)',
    },
  }));

export default AntTab