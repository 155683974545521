import React, { useContext, useEffect } from 'react';
import "./Login.css"
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import AppContext from '../../Context/AppContext';

const Login = () => {

    const BASE_URL_LOC = process.env["REACT_APP_SERVER_LOCATION"];
    const navigate = useNavigate();

    const appStates = useContext(AppContext);


    useEffect(()=>{
        if (appStates.getToken() !== null){
            navigate("/home")
        }
    })

    async function getUserInfo(codeResponse) {
        var response = await fetch(BASE_URL_LOC+"/google_login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ code: codeResponse.code }),
        });

        if(response.status === 200){
            let body = await response.json();
            let tokenValue = jwtDecode(body.token)
            
            await localStorage.setItem("userToken", body.token);
            await sessionStorage.setItem("userToken", body.token)
    
            await appStates.uploadToken(body.token);
    
            navigate("/home", {state : {"role" : tokenValue.userRole}})
    
            return await body;
        }else{
            return await response.json();
        }
    }

    const googleLogin = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async (codeResponse) => {
            await getUserInfo(codeResponse);
        },
        onError : async (err) =>{
            console.log(err);
        }
    });

    return (
        <>
        <div className='login_container'>
            <div className='login_card'>
                <div className='label_card'>Login</div>
                <div className='google_sign_in_Btn' onClick={ googleLogin }>
                <FcGoogle size={ "40px" } />
                Sign in With Google
                </div>
            </div>
        </div>
        </>
    )
}

export default Login