import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

{/* The Component is used to Restrict Back Presses and Uses Cofirms for Actions  */ }

const PageLeaveWrapper = ({ children, cleanUpCall = ()=>{console.log("CleanUp");} }) => {

    const navigate = useNavigate();

    useEffect(() => {
        const unloadCallback = async (event) => {
            event.preventDefault();
            event.returnValue = "";
            cleanUpCall();
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => {
            console.log("BeforeUnload");
            cleanUpCall();
            // window.addEventListener("popstate", confirmation());
            window.removeEventListener("beforeunload", unloadCallback);
        }
    }, []);

    const onBackButtonEvent = async (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to go back ?")) {
            navigate(-1)

        } else {
            window.history.pushState(null, null, window.location.pathname);
        }
    }

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    return (
        <>
            { children }
        </>
    )
}

export default PageLeaveWrapper