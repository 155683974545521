import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const Loading = () => {

        return (
            <>

                <Backdrop
                    sx={ { 
                        color: 'fff', 
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        display : "flex",
                        fontSize : "30px",
                        gap : "1rem"
                    } }
                    open={ true }
                >
                    <CircularProgress color="success"  />
                    Loading
                </Backdrop>
            </>
        )
}

export default Loading