import React, { useEffect, useState } from 'react';
import CreateGetFetch from '../../ServerCalls/CreateGetFetch';
import { Grid } from "@mui/material"
import ReadOnlyTable from "../../Component/DataGrids/AGGrid/ReadOnlyTable";
import CacheManager from '../../Utils/CacheManage';

const LocalityPage = () => {
    // const { data } = useFetch("/getLocalityTable");

    const [data, setData] = useState({"data" : [], "headers" : []});

    const getObject = CreateGetFetch();
    const cacheManager = new CacheManager();

    const handleCacheCall = async () => {
        if ( cacheManager.doesExists("localityMap")){
            setData(cacheManager.getEntry("localityMap"))
        }else{
            let res = await getObject.caller("/getLocalityTable");
            let _data = await res.json();
            setData(_data);
            cacheManager.setEntry("localityMap", _data);
        }
    };

    useEffect(() => {
        if ( data.data.length === 0 ){
            handleCacheCall();
        }
    },[data]) 

    return (
        <Grid container>
            <Grid item sm={12} md={12} lg={10}>
            <div style={{ boxSizing:"border-box", width:"100%", paddingInlineStart:"0.5rem" }}>
                <div
                    className="ag-theme-quartz" // applying the grid theme
                    style={ { height: 500, width:"100%" } } // the grid will fill the size of the parent container
                >
                    <ReadOnlyTable
                        TableData={ data }
                    />

                </div>
            </div>
            </Grid>

            <Grid item lg={12} sm={12} md={12} margin={"2rem"}/>
        </Grid>
        
    )
}

export default LocalityPage