import './App.css';
import QCPage from './Pages/QCPage/QCPage';
import Home from './Pages/Home/Home';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './Pages/Login/Login';
import { GoogleOAuthProvider } from "@react-oauth/google"
import AppStates from './Context/AppStates';
import ProtectedRoutes from './RouteComponents/ProtectedRoutes';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import ErrorPage from './Pages/ErrorPage/ErrorPage';
import Normal from './Pages/Home/Normal';
import SpeciesPage from './Pages/SourceData/SpeciesPage';
import History from './Pages/History/History';
import MainLayout from './Component/Layout/MainLayout/MainLayout';
import React from 'react';
//API
import { mergeAnalysisLoader, historyDataLoader, adminStatsLoader, mergeDataLoader, mergeConflictLoader, pageDataLoader } from './Api/ReadOnlyFetchData/GetRequests';
import LocalityPage from './Pages/SourceData/LocalityPage';
import UserAccount from './Pages/Admin/AdminComponents.js/UserAccount';

const ImageTagger = React.lazy(() => import("./Pages/QCPage/ImageTagger"))
const Admin = React.lazy(() => import('./Pages/Admin/Admin'));
const AdminRoutes = React.lazy(() => import('./RouteComponents/AdminRoutes'))
const StatsTab = React.lazy(() => import('./Pages/Admin/AdminComponents.js/StatsTab'))
const OperationsTab = React.lazy(() => import('./Pages/Admin/AdminComponents.js/OperationsTab'))
const UsersTab = React.lazy(() => import('./Pages/Admin/AdminComponents.js/UsersTab'))
const MergeAnalysis = React.lazy(() => import('./Pages/Admin/AdminComponents.js/MergeAnalysis'))
const MergeConflicts = React.lazy(() => import('./Pages/Admin/AdminComponents.js/MergeConflicts'))
const ConflictResolver = React.lazy(() => import('./Pages/ConflictResolver/ConflictResolver'))
// register Handsontable's modules
registerAllModules();

function App() {
  const clientID = process.env["REACT_APP_OAUTH_CLIENT_ID"];

  const router = createBrowserRouter([
    {
      path: "/",
      Component: MainLayout,
      children: [
        {
          index: true,
          Component: Login,
        },
        {
          path: "login",
          Component: Login,
        },
        {
          path: "home",
          Component: () => <ProtectedRoutes> <Home /> </ProtectedRoutes>,
          children : [
            {
              index : true,
              Component : () => <ProtectedRoutes> <Normal /> </ProtectedRoutes>,
              errorElement: <ErrorPage /> 
            },
            {
              path : "base",
              Component : () => <ProtectedRoutes> <Normal /> </ProtectedRoutes>,
              errorElement : <ErrorPage />
            },
            {
              path: "admin",
              Component : () => <AdminRoutes> <Admin /> </AdminRoutes>,
              errorElement: <ErrorPage />,
              children : [
                {
                  index : true,
                  Component : () => <StatsTab />,
                  // loader: adminStatsLoader,
                  errorElement: <ErrorPage/>,
                  shouldRevalidate: ()=>false,
                },
                {
                  path : "stats",
                  Component : () => <StatsTab />,
                  loader: adminStatsLoader,
                  errorElement: <ErrorPage/>,
                  shouldRevalidate: ()=>false,
                },
                {
                  path : "operation",
                  Component : () => <OperationsTab />,
                  errorElement : <ErrorPage />
                },
                {
                  path : "users",
                  Component : () => <UsersTab />,
                  errorElement : <ErrorPage />
                },
                {
                  path : "mergeAnalysis",
                  Component : () => < MergeAnalysis />,
                  errorElement : <ErrorPage />,
                  loader : mergeAnalysisLoader,
                  shouldRevalidate : () => false,
                },
                {
                  path : "mergeConflicts",
                  Component : () => <MergeConflicts />,
                  loader : mergeConflictLoader,
                  shouldRevalidate : () => false,
                  errorElement : <ErrorPage />
                },
                {
                  path : "useraccount",
                  children : [
                    {
                      path : ":userID",
                      Component : () => <UserAccount />
                    },
                    {
                      index : true,
                      Component : () => <UserAccount />
                    }
                  ],
                  errorElement : <ErrorPage />
                }
              ]
            },
            {
              path: "specieslist",
              Component : SpeciesPage,
              errorElement: <ErrorPage />,
            },
            {
              path:"localitylist",
              Component : LocalityPage,
              errorElement: <ErrorPage />,
            }
          ]
        },
        {
          path: "qc_page/:year/:page/:status/:type",
          Component: () => <ProtectedRoutes> <QCPage /> </ProtectedRoutes>,
          loader: pageDataLoader,
          errorElement: <ErrorPage/>,
          shouldRevalidate: ()=>false,
        },
        {
          path: "qc_revisit_page/:year/:page/:status/:type",
          Component: () => <ProtectedRoutes> <QCPage /> </ProtectedRoutes>,
          loader: pageDataLoader,
          errorElement: <ErrorPage />,
          shouldRevalidate: ()=>false,
        },
        {
          path: "/ConflictResolver/:year/:page",
          Component: () => <ProtectedRoutes> <ConflictResolver /> </ProtectedRoutes>,
          loader: mergeDataLoader,
          errorElement: <ErrorPage />,
          shouldRevalidate: ()=>false,
        },
        {
          path: "image_page/:year/:page",
          Component: () => <ProtectedRoutes> <ImageTagger /> </ProtectedRoutes>
        },
        {
          path: "/pageHistory/:fileName",
          Component: () => <AdminRoutes><History /></AdminRoutes>,
          loader: historyDataLoader,
          errorElement: <ErrorPage />,
          shouldRevalidate: ()=>false,
        }
      ],
      errorElement:<ErrorPage />
    },
    {
      path: "*",
      Component: ErrorPage
    }
  ])

  return (
    <GoogleOAuthProvider clientId={ clientID }>
      <AppStates>
        <div className="App">

          <RouterProvider router={ router } />

        </div>
      </AppStates>
    </GoogleOAuthProvider>
  );
}

export default App;