import { alphaOnly, dateValidator, digits, digitsAndSpace, sexValidator, speciesCodeValidator, ringSizeValidation, weightRingSizeValidation, localityValidation } from "../Validations/MergeValidators"

const DataBaseValidation = ( tableApi, data, setSnackBar ) =>{

  let mergeAllow = true;
  let warnUser = false;
  for (var index in data) {
      let row = data[index];
      let objectKeys = Object.keys(row);

      //? Priority Error Checks

      if (objectKeys.includes("RingNo")) {
        if (digits(('' + row["RingNo"]).trim())) {
          setSnackBar(true, "Data Validation Error : Ring No Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "RingNo")
          mergeAllow = false;
          break;
        }
      }

      if (objectKeys.includes("Species")) {
        if (alphaOnly(row["Species"])) {
          setSnackBar(true, "Data Validation Error : Species Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "Species")
          mergeAllow = false;
          break;
        }
      }

      if ( objectKeys.includes("Locality") ){
        if ( !localityValidation(row["Locality"]) ){
          setSnackBar(true, "Locality Empty! If Unknown kindly fill \"Unknown\"", "error");
          tableApi.selectCell(parseInt(index), "Locality");
          mergeAllow = false;
          break;
        }
      }

      if (objectKeys.includes("SpeciesCode")) {
        if (speciesCodeValidator(row["SpeciesCode"])) {
          setSnackBar(true, "Data Validation Error : Species Code Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "SpeciesCode")
          mergeAllow = false;
          break;
        }
      }

      if (objectKeys.includes("RingSize")) {
        if (!ringSizeValidation(row["RingSize"])) {
          setSnackBar(true, "Data Validation Error : Ring Size Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "RingSize");
          mergeAllow = false;
          break;
        }
      } else {
        setSnackBar(true, "RingSize Missing at Row - "+index, "error")
        mergeAllow = false;
      }

      if (objectKeys.includes("Date")) {
        if (dateValidator(('' + row["Date"]).trim())) {
          setSnackBar(true, "Data Validation Error : Date Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "Date");
          mergeAllow = false;
          break;
        }
      }

      if (objectKeys.includes("Weight")) {
        if (digitsAndSpace(('' + row["Weight"]).trim())) {
          setSnackBar(false, "Validation Failed : Weight Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "Weight")
          mergeAllow = false;
          warnUser = true;
        }
      }
      
      if (objectKeys.includes("Wing")) {
        if (digitsAndSpace(('' + row["Wing"]).trim())) {
          setSnackBar(false, "Validation Failed : Wing Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "Wing")
          mergeAllow = false;
          warnUser = true;
        }
      }

      // ? Secondary Error Checkss

      if (objectKeys.includes("Weight")) {
        if (weightRingSizeValidation(row["Weight"], row["RingSize"])) {
          warnUser = true;
        }
      }

      if (objectKeys.includes("Bills")) {
        if (digitsAndSpace(('' + row["Bills"]).trim())) {
          setSnackBar(false, "Validation Failed : Bills Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "Bills")
          mergeAllow = false;
          warnUser = true;
        }
      }

      if (objectKeys.includes("Tars")) {
        if (digitsAndSpace(('' + row["Tars"]).trim())) {
          setSnackBar(false, "Validation Failed : Tars Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "Tars")
          mergeAllow = false;
          warnUser = true;
        }
      }
      
      if (objectKeys.includes("Moult")) {
        if (digitsAndSpace(('' + row["Moult"]).trim())) {
          setSnackBar(false, "Validation Failed : Moult Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "Moult")
          mergeAllow = false;
          warnUser = true;
        }
      }

      
      if (objectKeys.includes("Brood")) {
        if (digitsAndSpace(('' + row["Brood"]).trim())) {
          setSnackBar(false, "Validation Failed : Brood Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "Brood")
          mergeAllow = false;
          warnUser = true;
        }
      }

      if (objectKeys.includes("Plumage")) {
        if (digitsAndSpace(('' + row["Plumage"]).trim())) {
          setSnackBar(false, "Validation Failed : Plumage Invalid at Row - "+(parseInt(index)+1), "error");
          tableApi.selectCell(parseInt(index), "Plumage")
          mergeAllow = false;
          warnUser = true;
        }
      }

      if (objectKeys.includes("Sex")) {
        if (!sexValidator(('' + row["Sex"]).trim())) {
          warnUser = true;
        }
      }
  }
  return {mergeAllow, warnUser}
}

export { DataBaseValidation }