import React, { useState, useRef, useEffect } from "react";
import { Grid, Snackbar, Alert, Switch, Button, Dialog } from "@mui/material";
import "./QCPage.css";
import { useParams, useNavigate, useLoaderData, useLocation } from "react-router-dom";
import ImageViewer from "../../Component/ImageViewer";
import PageDetails from "../../Component/PageDetails";
import { motion, AnimatePresence } from "framer-motion"
import HandsOnTable from "../../Component/DataGrids/HandsOnTable/HandsOnTable";
import CreatePostFetch from "../../ServerCalls/CreatePostFetch";
import LoadingWrapper from "../Loading/LoadingWrapper";
import { RetriveDataFromTable } from "../../Utils/RetriveDataFromTable";

import PageLeaveWrapper from "../../Utils/PageLeaveWrapper";
import { ErrorMerger } from "../../Utils/ErrorMerger";
import { storeLastVisit } from "../../Utils/StoreLastVisit";
import { DataBaseValidation } from "../../Utils/Validations/DataBaseValidation";
import ConflictDisplay from "../../Component/DialogContainer/ConflictResult/ConflictDisplay";

const QCPage = () => {

  const loadedData = useLoaderData();

  const [[snackBarVal, snackBarMsg, snackBarType], setSnackBar] = useState([false, "File Saved", "info"]);
  const { page, year, status } = useParams();
  const location = useLocation();

  const [imageUrl] = useState(loadedData.img);
  const [backImg] = useState(loadedData.back_url);
  const [TableData, setTableData] = useState(loadedData);

  const [pageload, setPageLoad] = useState(false);

  const [detailsToggle, setdetailsToggle] = useState(localStorage.getItem("userPref_details") ? JSON.parse(localStorage.getItem("userPref_details")) : false);
  const [[conflictPop, conflictsData], setConflictsPop] = useState([false, []])

  const navigate = useNavigate();
  const TableRef = useRef(null);
  const pageDetailsRef = useRef(null);
  const deltedRows = useRef([]);
  const updatedRows = useRef([]);

  const postBuilder = CreatePostFetch();

  useEffect(() => {
    const navSplit = location.pathname.split("/")
    storeLastVisit({
      "filePage": navSplit[1],
      "fileName": page,
      "fileYear": year,
      "fileStatus": status,
      "fileType": navSplit[5],
    })
  }, [])

  const pageLeaveCleanUp = async () => {
    const BASE_URL_LOC = process.env["REACT_APP_SERVER_LOCATION"];

    await fetch(BASE_URL_LOC + "/pageLeave?fileName=" + page + "&fileYear=" + year, {
      method: "GET",
      redirect: "follow",
      headers: {
        "authentication-token": localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "authentication-token",
        "Access-Control-Expose-Headers": "authentication-token",
      },
      mode: "cors"
    })

  }

  const saveTable = async (saveType) => {

    await pageDetailsRef.current.saveDetails();

    if (TableRef !== null) {

      let data = RetriveDataFromTable(TableRef);
      const tableHeaders = TableRef.current.hotInstance.getColHeader();

      let formData = new FormData()
      formData.append("data", JSON.stringify(data))
      formData.append("headers", JSON.stringify(tableHeaders))
      formData.append("fileName", page)
      formData.append("fileYear", year);
      formData.append("saveType", saveType);
      formData.append("errorObj", JSON.stringify(TableData.errorList))
      let response = await postBuilder.caller("/saveFile", formData);


      if (response.status === 200) {
        setSnackBar([true, "File Saved", "success"]);

        if (saveType === true) {
          setTimeout(() => {
            navigate("/home")
          }, 1000);
        }

      } else {
        setSnackBar([false, "File Not Submitted", "error"])
      }

    }
  }

  const handleSnackBarState = (val, msg, type) =>{ setSnackBar([true, msg, type]) }

  const mergeTableCall = async () => {
    setPageLoad(true);

    if (TableRef !== null) {

      const tableApi = TableRef.current.hotInstance;

      let data = RetriveDataFromTable(TableRef);
      const tableHeaders = TableRef.current.hotInstance.getColHeader();

      let { mergeAllow, warnUser } = DataBaseValidation(tableApi, data, handleSnackBarState);
      console.log("MERGE CALLED", mergeAllow, warnUser);

      let formData = new FormData()
      formData.append("data", JSON.stringify(data))
      formData.append("headers", JSON.stringify(tableHeaders))
      formData.append("fileName", page)
      formData.append("fileYear", year);

      if (mergeAllow) {
        if (warnUser) {

          let res = window.confirm("The Page contains errors. Do you still wish to merge?");
          if (res) {

            let response = await postBuilder.caller("/getFinalMergedData", formData);

            if (response.status === 200) {
              let data = await response.json();
              console.log("MERGE RESPONSE ", data);
              setTableData(prevState => {
                return {
                  "img": imageUrl,
                  "fileName": prevState.fileName,
                  "fileYear": prevState.fileYear,
                  "data": data.data,
                  "headers": data.headers,
                  "errorList": ErrorMerger(prevState.errorList, data.errorList),
                  "back_url": backImg
                }
              });
              setSnackBar([true, "File has been Merged", "success"]);
              if (data.mergeErrors?.length !== 0) {
                setConflictsPop([true, data.mergeErrors])
              }
            } else {
              let responseText = await response.text();
              setSnackBar([true, "Merge Failed :" + responseText, "error"]);
            }
          }

        } else {
          let response = await postBuilder.caller("/getFinalMergedData", formData);
          if (response.status === 200) {
            let data = await response.json();
            setTableData(prevState => {
              return {
                "img": imageUrl,
                "fileName": prevState.fileName,
                "fileYear": prevState.fileYear,
                "data": data.data,
                "headers": data.headers,
                "errorList": ErrorMerger(prevState.errorList, data.errorList),
                "back_url": backImg
              }
            });
            setSnackBar([true, "File has been Merged", "success"]);
            if (data.mergeErrors?.length !== 0) {
              setConflictsPop([true, data.mergeErrors])
            }
          } else {
            let responseText = await response.text();
            setSnackBar([true, "Merge Failed :" + responseText, "error"]);
          }
        }
      }

    }


    setPageLoad(false);
  }

  const handleDelCallBack = (id) => {
    deltedRows.current.push(id);
  }

  const handleUpdateCallback = (index) => {
    if (TableRef.current.hotInstance.getDataAtRowProp(index, "database_id") !== null) {
      updatedRows.current.push(TableRef.current.hotInstance.getDataAtRowProp(index, "database_id"));
    }
  }

  const remergeTableCall = async () => {
    setPageLoad(true);

    let confirm = window.confirm("Remerging a file will ignore non marjor errors and will mark rows as valid are you sure you want to proceed ?")

    if (confirm) {

      console.log("Remerge")
      if (TableRef !== null) {

        const tableApi = TableRef.current.hotInstance;

        let data = RetriveDataFromTable(TableRef);
        const tableHeaders = TableRef.current.hotInstance.getColHeader();

        let { mergeAllow, warnUser } = DataBaseValidation(tableApi, data, handleSnackBarState);
        console.log("Remerge", mergeAllow, warnUser)
        let insertedList = []
        insertedList = data.filter(e => e['database_id'] === -1);
        data = data.filter(e => e['database_id'] !== -1);

        let formData = new FormData()
        formData.append("data", JSON.stringify(data))
        formData.append("headers", JSON.stringify(tableHeaders))
        formData.append("fileName", page)
        formData.append("fileYear", year);
        formData.append("insertedList", JSON.stringify(insertedList));
        formData.append("deletedList", JSON.stringify(deltedRows.current));
        formData.append("updatedList", JSON.stringify(updatedRows.current));

        if (mergeAllow) {
          if (warnUser) {

            let res = window.confirm("The Page contains errors. Do you still wish to merge?");
            if (res) {

              let response = await postBuilder.caller("/remergeData", formData);

              if (response.status === 200) {
                let data = await response.json();
                data = { ...data, "img": imageUrl, 'back_url': backImg };
                setTableData(prevState => {
                  return {
                    "img": imageUrl,
                    "fileName": prevState.fileName,
                    "fileYear": prevState.fileYear,
                    "data": data.data,
                    "headers": data.headers,
                    "errorList": ErrorMerger(prevState.errorList, data.errorList),
                    "back_url": backImg
                  }
                });
                setSnackBar([true, "File has been Merged", "success"]);
                if (data.mergeErrors?.length !== 0) {
                  setConflictsPop([true, data.mergeErrors])
                }
              } else {
                setSnackBar([true, "Merge Failed", "error"]);
              }
            }

          } else {
            let response = await postBuilder.caller("/remergeData", formData);
            if (response.status === 200) {
              let data = await response.json();
              data = { ...data, "img": imageUrl, 'back_url': backImg };
              setTableData(prevState => {
                return {
                  "img": imageUrl,
                  "fileName": prevState.fileName,
                  "fileYear": prevState.fileYear,
                  "data": data.data,
                  "headers": data.headers,
                  "errorList": ErrorMerger(prevState.errorList, data.errorList),
                  "back_url": backImg
                }
              });
              setSnackBar([true, "File has been Merged", "success"]);
              if (data.mergeErrors?.length !== 0) {
                setConflictsPop([true, data.mergeErrors])
              }
            } else {
              setSnackBar([true, "Merge Failed", "error"]);
            }
          }
        }

      }
    }

    setPageLoad(false);
  }

  const handleRevertCall = async () => {
    let performAction = window.confirm("Do you wish to Revert file status back to 'QC_Done' ?")
    if (performAction) {
      const formBody = new FormData();
      formBody.append("fileName", page);
      const res = await postBuilder.caller("/revertQcDone", formBody)
      if (res.status === 200) {
        setSnackBar([true, "File Reverted. Kindly Exit the Page", "success"])
      } else {
        setSnackBar([true, "Revert Failed", "error"])
      }
    }
  }

  const handleCloseDialog = () => { setConflictsPop(prevState => [false, prevState[1]]) }

  return (
    <PageLeaveWrapper cleanUpCall={ pageLeaveCleanUp } >
      <Grid container justifyContent={ "center" } >
        <Grid container item sm={ 12 } md={ 12 } lg={ 12 }>

          <Grid item sm={ 12 } md={ 6 } lg={ 5 }
            height={ "100vh" }
            sx={ {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            } }
          >
            <ImageViewer
              imageUrl={ imageUrl }
              backImg={ backImg }
            />

          </Grid>

          <Grid item sm={ 12 } md={ 6 } lg={ 7 } height={ "100vh" } display={ "flex" } flexDirection={ 'column' }>
            <HandsOnTable
              ref={ TableRef }
              TableData={ TableData }
              delRowsCallback={ handleDelCallBack }
              updatedRowsCallBack={ handleUpdateCallback }
            // TableLoading={ TableLoading }
            />
          </Grid>

          <Grid item sm={ 12 } md={ 12 } lg={ 12 }>
            <div className="details_subsection">
              <AnimatePresence mode="wait">
                { detailsToggle && (
                  <motion.div
                    initial={ { opacity: 0 } }
                    animate={ { opacity: 1 } }
                    exit={ { opacity: 0 } }
                    transition={ { duration: 1 } }
                    key={ "subsection_details_toggle" }
                    className="toggle_subsection"
                  >
                    <Switch
                      checked={ !detailsToggle }
                      onChange={ () => { setdetailsToggle(!detailsToggle) } }
                    />
                    Show Page Tagging Panel
                  </motion.div>
                ) }

                { !detailsToggle && (
                  <motion.div
                    initial={ { height: 0 } }
                    animate={ { height: 'auto' } }
                    exit={ { height: 0 } }
                    transition={ { duration: 1, ease: 'easeInOut' } }
                    key={ "subsection_detailsPannel" }
                  >
                    <PageDetails
                      ref={ pageDetailsRef }
                      toggleController={ setdetailsToggle }
                      year={ year }
                      page={ page }
                      setSnackBar={ setSnackBar }
                      showOptions={ false }

                    />
                  </motion.div>
                ) }
              </AnimatePresence>
            </div>
          </Grid>

          <Grid item sm={ 12 } md={ 12 } lg={ 12 } display={ 'flex' } justifyContent={ 'center' } gap={ '1rem' } alignItems={ 'center' } marginTop={ '1rem' } marginBottom={ '1rem' }>

            {
              ((status === "QC_Done" || status.toLowerCase().endsWith("done")) && (
                <>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={ () => { handleRevertCall(page) } }>
                    Revert
                  </Button>
                </>
              ))
            }

            <Button onClick={ () => { saveTable(false) } } variant="contained" color="success">
              Save
            </Button>

            <Button
              onClick={ () => {
                if (window.confirm("Are you sure you want to Submit the file? Once submitted changes cannot be made to the file.")) {
                  saveTable(true);
                }
              }
              }
              variant="contained"
              color="success"
            >
              Submit
            </Button>

            { (status === "QC_Done" || status.toLowerCase().endsWith("done")) && (
              <Button
                variant="contained"
                color="success"
                onClick={ TableData.headers.includes("database_id") ? remergeTableCall : mergeTableCall }>
                Merge
              </Button>
            ) }

            {
              ((status === "Merged") || TableData.headers.includes("database_id") ) &&
              (
                <Button
                  variant="contained"
                  color="success"
                  onClick={ TableData.headers.includes("database_id") ? remergeTableCall : () => { console.log("Click") } }
                >
                  ReMerge
                </Button>
              )
            }

          </Grid>
        </Grid>

        { pageload && (<LoadingWrapper />) }


        <Snackbar
          open={ snackBarVal }
          key={ "SnackBar" }
          autoHideDuration={ 1000 }
          onClose={ () => { setSnackBar(prevState => [false, prevState[1], prevState[2]]) } }
        >
          <Alert
            onClose={ () => setSnackBar([false, snackBarMsg, snackBarType]) }
            severity={ snackBarType }
            variant="filled"
            sx={ { width: '100%' } }
          >
            { snackBarMsg }
          </Alert>
        </Snackbar>

        <Dialog
          fullWidth={ true }
          maxWidth={ 'md' }
          open={ conflictPop }
          onClose={ handleCloseDialog }
        >
          <ConflictDisplay conflictsData={ conflictsData } />
        </Dialog>

      </Grid>
    </PageLeaveWrapper>
  );
};

export default QCPage;
