import { TextField } from "@mui/material";
import styled from "@emotion/styled";
const CustomTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        color: "var(--font-color-primary)",
        fontSize: 20,
    },
    "& label":{
      color: "var(--font-color-primary)"
    },
    '& label.Mui-focused': {
      color: "var(--font-color-primary)",
    },
    '& .MuiInput-underline:after': {
      borderBottomColor:  'var(--accent-color)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor:  'var(--accent-color)',
      },
      '&:hover fieldset': {
        borderColor:  'var(--accent-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor:  'var(--accent-color)',
      },
    },
  });

export default CustomTextField;