import React from 'react'
import { NavLink, useRouteError } from 'react-router-dom'

const ErrorPage = () => {

  const error = useRouteError();  

  if ( error.status === 409 ){
    return (
      <>  
        <h1> Conflict Error </h1>
        <h5> The current file you are trying to accesss is being used by somebody else</h5>
        <NavLink to={"/home"} >
          Back To Home
        </NavLink>
      </>
    )
  }

  if ( error.status === 410 ){
    return (
      <>  
        <h1> Page Status Error </h1>
        <h5> The current file you are trying to accesss is merged into database and access is restricted by Admin</h5>
        <NavLink to={"/home"} >
          Back To Home
        </NavLink>
      </>
    )
  }
  
  return (
    <div>

      {
        error !== null && (
          <>
          <h1>
            Error Occurred
          </h1>
          </>
        )
      }

      {
        error === null && (
          <>
            <h1>404 : Not Found</h1>
            <NavLink to={"/home"} >
                Back To Home
            </NavLink>
          </>
        )
      }

    </div>
  )
}

export default ErrorPage