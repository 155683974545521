import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useNavigation } from 'react-router-dom';
import LoadingWrapper from "../Loading/LoadingWrapper";

const History = () => {

    const historyPageData = useLoaderData();
    
    const { state } = useNavigation();

    
    const [cols, setCols] = useState([]);

    useEffect(()=>{
        if ( historyPageData.length !== 0){
            setCols(Object.keys(historyPageData[0]).map(e=>{ return { field: e, headerName: e } }))
        }
    },[historyPageData]);

    if ( state === "loading" ){
        return <LoadingWrapper />
    }
    return (
        <div sx={ { width: "100%", padding: "1rem" } }>
            <div
                className="ag-theme-quartz" // applying the grid theme
                style={ { height: "65vh", flex: 1 } } // the grid will fill the size of the parent container
            >
                <AgGridReact
                    rowData={ historyPageData }
                    enableCellTextSelection={ true }
                    ensureDomOrder={ true }
                    columnDefs={ cols }
                    defaultColDef={ {
                        cellDataType: 'text',
                        filter: true,
                        editable: false,
                        sortable : true,
                        flex: 1
                    } }
                />
            </div>
        </div>
    )
}

export default History