import React from 'react';
import { Link } from "react-router-dom";
import { Grid } from '@mui/material';

const DuplicateRingCard = ({ object = {} }) => {

    const styles = {
        cardContainer : {
            padding: "1%",
            border: "solid",
            borderRadius: "5px",
            borderColor: "var(--secondary-color)",
            background: "var(--background-color-secondary)",
            paddingBottom : "5px",
            paddingInline : "10px",
            overflow : "hidden",
            minHeight : "155px"
        },
        rowColIndication : {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            color: "var(--fontColorPrimaryLight)",
            paddingBottom : "5px"
        }, 
        messageStyle : {
            paddingBottom : "15px",
            textWrap : "pretty"
        },
        urlPaths : {
            textWrap : "pretty",
        },
        itemUniqueStyle : {
            textWrap : "nowrap",
            flex : "0.5",
        }
    } 

    return (
        <Grid item sm={12} md={6} lg={3}>
        <div key={ object.type + object.row } style={styles.cardContainer} >
            <div style={styles.rowColIndication}>
                <span style={styles.itemUniqueStyle}>Row: { object.row }</span> 
                <span style={styles.itemUniqueStyle}>Col: { object.col }</span>
            </div>
            <div style={styles.messageStyle} >Message : { object.message }</div>
            Links:
            <div style={styles.urlPaths}>
            { object.page.map(page => {
                return (
                    <Link to={ "/qc_revisit_page/" + page.year + "/" + page.pageName + "/QC_Done/getQCTable" }
                        target="_blank" rel="noopener noreferrer"
                    >
                        { page.pageName }
                    </Link>
                )
            }) }
            </div>
        </div>
        </Grid>
    )
}

export default DuplicateRingCard;
