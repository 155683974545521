function storeLastVisit ( newEntry ){
    let initialArr = localStorage.getItem("lastVisited") ? JSON.parse(localStorage.getItem("lastVisited")) : []
    initialArr.reverse();

    if ( ! ( initialArr.some(e => e.fileName === newEntry["fileName"]) ) ){
        initialArr.push(newEntry)
        initialArr.reverse();

        if(initialArr.length >= 6){
            initialArr = initialArr.slice(0, 5);
        }
    }

    localStorage.setItem("lastVisited", JSON.stringify(initialArr))
}
export { storeLastVisit };

/*
    {
        "filePage" : "",
        "fileName" : "",
        "fileYear" : "",
        "fileStatus" : "",
        "fileType" : "",
    }
*/