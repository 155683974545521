import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
const ReadOnlyTable = ( { 
    TableData = { "data" : [], "headers" : [] } ,
    ColumnConfig = {},
    ExcessColumns = [], 
    config = {
        onCellDoubleClicked : () => console.log("CellDoubleClicked"),
    } }
    ) => {
  return (
    <>
        <div style={ { width: "100%", padding: "1rem" } }>
                <div
                    className="ag-theme-quartz" // applying the grid theme
                    style={ { height: 500 } } // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={ TableData.data }
                        columnDefs={ [
                            ...TableData.headers.map(e => {
                                if ( typeof ColumnConfig[e] != 'undefined' ){
                                    return ColumnConfig[e]
                                }
                                return { field : e } }
                            ),
                            ...ExcessColumns
                        ] }
                        defaultColDef={
                            {
                                sortable: true,
                                filter: true,
                                editable: false
                            }
                        }
                        onCellDoubleClicked={config.onCellDoubleClicked}

                    />
                </div>
            </div>
    </>
  )
}

export default ReadOnlyTable