import React from 'react';
import "../../Components.css";
import Logo from "./Logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { Button } from "@mui/material"

const Header = ({toggleDrawer}) => {

  const alignItemsStyle = { display : "flex", alignItems : "center", flexDirection : "row", gap :"15px" }

  return (
    <header className='header' key={"HeadersComponenet"}>
        <div className='drawer_container'>
          <Button onClick={toggleDrawer(true)} variant='contained' color='success'>
            <div style={alignItemsStyle}>
              <GiHamburgerMenu scale={"1rem"}  />
              <span>MENU</span>
            </div>
          </Button>
        </div>
        <img src={Logo} alt='Logo' className='header_image' />
        <span>BNHS Bird Ringing Project</span>
    </header>
  )
}

export default Header