import React, { useEffect, useRef, useState } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { MdOutlineZoomIn, MdOutlineZoomOut, MdClear, MdOutlineFlipCameraAndroid } from 'react-icons/md';

const ImageViewer = ({ imageUrl, backImg }, 
style={
    "position": "relative",
    "display": "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
}
) => {

    const [sideImg, setFlipImg] = useState(true);
    const [zoom, setZoom] = useState(1.0);
    const imageRef = useRef(null);
    //Event Listner for Zoom
    useEffect(() => {
        if (imageRef.current) {
            imageRef.current.setTransform(zoom);
        }
    }, [zoom]);

    return (
        <div style={style}>
            <TransformWrapper
                ref={ imageRef }
                onZoomStop={ (imageRef) => {
                    setZoom(imageRef.state.scale);
                } }
            >
                { ({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <div className="image_container_div">
                        <div className="image_transform_tools">
                            <button className="img_cntrl_btn" onClick={ () => zoomIn() }>
                                <MdOutlineZoomIn size={ "20px" } />
                            </button>
                            <button className="img_cntrl_btn" onClick={ () => zoomOut() }>
                                <MdOutlineZoomOut size={ "20px" } />
                            </button>
                            <button
                                className="img_cntrl_btn"
                                onClick={ () => resetTransform() }>
                                <MdClear size={ "20px" } />
                            </button>
                            <button
                                className="img_cntrl_btn"
                                disabled={ backImg === "null" || backImg === "" || backImg === null }
                                onClick={ () => setFlipImg(prevState => !prevState) }>
                                <MdOutlineFlipCameraAndroid size={ "20px" } />
                            </button>
                        </div>
                        <TransformComponent>
                            { sideImg && <img src={ imageUrl } alt="" className="pageImg" /> }
                            { !sideImg && <img src={ backImg } alt="" className="pageImg" /> }
                        </TransformComponent>
                    </div>
                ) }
            </TransformWrapper>
        </div>
    )
}

export default ImageViewer