import React,{ useContext } from 'react';
import "./Home.css"
import AntTabs from "../../Component/MuiComponent/Tabs/AntTabs"
import AntTab from "../../Component/MuiComponent/Tabs/AntTab"
import AppContext from '../../Context/AppContext';
import LoadingWrapper from "../Loading/LoadingWrapper";
import { useNavigation, Outlet, NavLink } from 'react-router-dom';
import useRouteMatch from "../../useHook/useRouteMatch"

const Home = () => {
  const { state } = useNavigation();

  const routeMatch = useRouteMatch(['/home/', '/home/specieslist', '/home/localitylist','/home/admin']);
  const currentTab = routeMatch?.pattern?.path;

  const appStates = useContext(AppContext);

  if ( state === "loading" ){
    return <LoadingWrapper />
  }

  return (
    <div className='page_container'>
      <div className='tabs_container'>
        <AntTabs
          value={currentTab}
        >
          <AntTab label="HOME"  value="/home/" to="/home/" component={NavLink} />
          <AntTab label="SPECIES LIST" value="/home/specieslist" to="/home/specieslist" component={NavLink} />
          <AntTab label="LOCALITY LIST" value="/home/localitylist" to="/home/localitylist" component={NavLink} />
          { appStates.getAuthStat() === "Admin" &&  
          <AntTab label="ADMIN" value="/home/admin" to="/home/admin" component={NavLink}  />}

        </AntTabs>
      </div>

      <Outlet />

    </div>
  )
}

export default Home;