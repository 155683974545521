import { useState, useContext } from "react";
import AppContext from "../Context/AppContext";

const CreateGetFetch = () => {

    const BASE_URL_LOC = process.env["REACT_APP_SERVER_LOCATION"];
    const [loading, setLoading] = useState(false);

    const appState = useContext(AppContext);

    const caller = async (url) => {
        setLoading(true);
        let response = { status: 200, data: {} };
        try {
            response = await fetch(BASE_URL_LOC + url, {
                method: "GET",
                redirect: "follow",
                headers: {
                    "authentication-token": appState.getToken(),
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "authentication-token",
                    "Access-Control-Expose-Headers": "authentication-token",
                },
                mode: "cors"
            })
        } catch (err) {
            response = { status: 500, data: err, statusText: "Error while Fetch" }
            setLoading(false);
        }

        if (response.status === 401) {

            let txt = await response.text();
            if (txt.includes("Token is invalid")) {
                console.log("Logging out user")
                localStorage.removeItem("userToken")
                localStorage.removeItem("token")
                window.location.reload()
            }
        }

        setLoading(false);
        return response;
    }

    return { loading, caller };
}

export default CreateGetFetch;