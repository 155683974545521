/**
            * @name CacheManger

            * @returns CacheManager Object
         */
class CacheManager {
    checkTimestamp(yourDate) {
        var EightHour = new Date().getTime() + (8 * 60 * 60 * 1000)
        if (yourDate < EightHour) {
            return true
        }
        else if (yourDate >= EightHour) {
            return false
        }
    }

    setEntry(name, data) {
        /**
            * @name setEntry
            * @param {string} name : Name of object 
            * @param {object} data : Object 
            *
            * Function sets object to localstorage
         */

        window.localStorage.setItem(name + "_data", JSON.stringify(data))
        window.localStorage.setItem(name + "_timestamp", JSON.stringify(Date.now()))

    }

    doesExists(name) {
        /**
            * @name doesExists
            * @param {string} name : Name of object 
            *
            * Function returns boolean if object exists is cache
            * 
            * @returns boolean
         */

        let timestamp = window.localStorage.getItem(name+"_timestamp")
        let data = JSON.parse(window.localStorage.getItem(name+"_data"))
        if ( timestamp === null){
            return false;
        }

        if (this.checkTimestamp(timestamp) && data !== null){
            return true;
        }

        return false;
    }

    getEntry(name) {
        /**
        * @name getEntry
        * @param {string} name : Name of object 
        *
        * Function returns object if object exists else null
        * 
        * @returns object
     */
        if ( this.doesExists(name) ){
            return JSON.parse(window.localStorage.getItem(name+"_data"));
        }else{
            return null
        }
    }

    removeEntry(name){
        /**
         * @name removeEntry
         * @param {string} name : Name of Object
         * 
         * Function removes objectw from storage
         * 
         * @returns null
         */
        if( this.doesExists(name) ){
            window.localStorage.removeItem(name+"_data");
            window.localStorage.removeItem(name+"_timestamp")
        }
    }

}

export default CacheManager;