import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Grid, FormControl, Checkbox, FormLabel, Radio, RadioGroup, FormControlLabel, FormGroup, TextField } from '@mui/material';
import CreatePostFetch from '../ServerCalls/CreatePostFetch';
import { motion } from 'framer-motion';
import useFetch from "../useHook/useFetch";
import "./Components.css";

const PageDetails = forwardRef(({ year, page, setSnackBar, showOptions=false, verticalStack=false }, _ref) => {

    const postFunc = CreatePostFetch();
    const [pageType, setPageType] = useState("Ringing_Page");
    const [additionInfo, setAdditionInfo] = useState({
        "resightingInfo": false,
        "othertext": ""
    });

    const { data, loading } = useFetch("/getPageTypeDetails?year=" + year + "&page=" + page);

    const [ringingData, setRingingData] = useState("");

    useImperativeHandle(_ref, () => ({
        saveDetails : setupPageType
    }));

    useEffect(() => {

        if (typeof data !== 'undefined') {
            setPageType(data.pageType ? data.pageType : "ringing_page");
            setRingingData(data.problemText ? data.problemText : "");
            setAdditionInfo({
                resightingInfo: (
                    typeof data.resightingInfo !== 'undefined' &&
                    data.resightingInfo !== null &&
                    data.resightingInfo !== 0
                ) ? data.resightingInfo : "",
                othertext: data.othertext ? data.othertext : ""
            })
        }

    }, [data, loading])

    const setupPageType = async () => {

        let formBody = new FormData();

        formBody.append("pageType", pageType);
        formBody.append("pageName", page);
        formBody.append("pageYear", year);
        formBody.append("resightingInfo", additionInfo.resightingInfo);
        formBody.append("otherText", additionInfo.othertext);
        formBody.append("problemText", ringingData);

        let response = await postFunc.caller("/savePageType", formBody);
        if (response.status === 200) {
            // navigate("/home")
            setSnackBar([true, "Page Details Added", "success"])
        } else {
            setSnackBar([true, "Failed To Update: " + response.status + " : " + response.statusText, "error"])
        }

    }

    return (
        <Grid container item sm={ 12 } md={ 12 } lg={ 12 } rowGap={ 1 }>
            <Grid item 
            sm={ verticalStack ? 12 : 6 } 
            md={ verticalStack ? 12 :6 } 
            lg={ verticalStack ? 12 : 5 } paddingInline={ "2rem" }>
                <motion.div className="page_type_options" style={ { flexDirection: "column", alignItems: "flex-start" } } key={ "pageDetails_left_section_details" }

                    initial={ { opacity: 0 } }
                    animate={ { opacity: 1, transition: { delay: 0.8 } } }
                    exit={ { opacity: 0 } }
                >
                    <div
                        style={ {
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            flexWrap: "nowrap",
                        } }
                    >

                        <div className='sub_details_section_container'>
                            <FormControl >
                                <FormLabel id='page_type_radio_group' 
                                sx={ { fontSize: "1.2rem" } }>Page Type</FormLabel>
                                <span className='information_light'>Select the type of page according to image</span>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={ pageType }
                                    onChange={ (event) => setPageType(event.target.value) }
                                    // sx={ { color: "whitesmoke" } }
                                >
                                    <FormControlLabel
                                        value="ringing_page"
                                        control={ <Radio /> }
                                        label="Ringing_Page"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="back_page"
                                        control={ <Radio /> }
                                        label="Back_Page"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="unuseable_page"
                                        control={ <Radio /> }
                                        label="Unusable_Page"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="speciesrecord_page"
                                        control={ <Radio /> }
                                        label="SpeciesRecord_Page"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <hr className='divider' />
                            <FormControl sx={ { display: "flex", alignItems: "flex-start", flexDirection: 'column', gap: '1rem' } }>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={ additionInfo.resightingInfo }
                                        onChange={ (e) => setAdditionInfo(prevState => {
                                            return {
                                                "resightingInfo": e.target.checked,
                                                "othertext": prevState.othertext
                                            }
                                        }) }
                                        inputProps={ { 'aria-label': 'controlled' } }
                                    />
                                }
                                    // sx={ { color: "whitesmoke" } }
                                    label="Does the image contain resighting/recapturing information?"
                                    labelPlacement='end' />
                                <FormControlLabel
                                    control={
                                        <TextField
                                            value={ additionInfo.othertext }
                                            onChange={ (e) => {
                                                setAdditionInfo(prevState => {
                                                    return {
                                                        "resightingInfo": prevState.resightingInfo,
                                                        "othertext": e.target.value
                                                    }
                                                })
                                            } }
                                            label={ "Other Info ( " + additionInfo.othertext.length + "/250 )" }
                                            size='small'
                                            inputProps={ { maxLength: 250 } }
                                        />
                                    }
                                    // sx={ { color: "whitesmoke" } }
                                    label="Any other information about the page: "
                                    labelPlacement='start' />

                            </FormControl>
                        </div>
                    </div>
                </motion.div>
            </Grid>
            <Grid item 
            sm={ verticalStack ? 12 : 6 } 
            md={ verticalStack ? 12 : 6 } 
            lg={ verticalStack ? 12 : 7 }>
                
                <motion.div className="page_type_options"
                    key={ "pageDetails_left_section_details" }
                    initial={ { opacity: 0 } }
                    animate={ { opacity: 1, transition: { delay: 0.8 } } }
                    exit={ { opacity: 0 } }
                    style={ { flexDirection: "column", alignContent: "flex-start" } }
                >
                    { pageType === 'ringing_page' && (
                        <motion.div key={ "Ringing_page_" }
                            initial={ { opacity: 0 } }
                            animate={ { opacity: 1, transition: { delay: 0.8 } } }
                            exit={ { opacity: 0 } }
                            style={ { width: "100%" } }
                            className='sub_details_section_container'
                        >
                            <FormGroup sx={ { width: "100%" } }>
                                <FormLabel id='ringingTypeCall' 
                                // sx={ { color: 'whitesmoke' } }
                                >Ringing Page</FormLabel>
                                <span className='information_light'>Issues Faced: </span>

                                <TextField
                                    multiline={ true }
                                    minRows={ 5 }
                                    value={ ringingData }
                                    onChange={ (e) => {
                                        setRingingData(prevState => e.target.value)
                                    } }
                                    label={ "Description ( " + ringingData.length + "/250 )" }
                                    size='small'
                                    sx={ { width: "80%", justifyContent: "start", gap: "1rem", marginTop:"10px", borderColor:"var(--accent-color)" } }
                                    placeholder='preffered format : row number/range - problem'
                                    inputProps={ { maxLength: 250 } }
                                />


                            </FormGroup>

                        </motion.div>
                    )
                    }
                    { pageType === 'back_page' && (
                        <motion.div key={ "Back_Page_" }
                            initial={ { opacity: 0 } }
                            animate={ { opacity: 1, transition: { delay: 0.8 } } }
                            exit={ { opacity: 0 } }
                        >
                        </motion.div>
                    )
                    }
                    { pageType === 'unuseable_page' && (
                        <motion.div key={ "Unuseable_Page_" }
                            initial={ { opacity: 0 } }
                            animate={ { opacity: 1, transition: { delay: 0.8 } } }
                            exit={ { opacity: 0 } }
                        >
                        </motion.div>)
                    }
                    { pageType === 'speciesrecord_page' && (
                        <motion.div key={ "SpeciesRecord_Page_" }
                            initial={ { opacity: 0 } }
                            animate={ { opacity: 1, transition: { delay: 0.8 } } }
                            exit={ { opacity: 0 } }
                        >
                        </motion.div>
                    )
                    }

                </motion.div>
            </Grid>

            { showOptions && (
                <Grid item sm={ 12 } md={ 12 } lg={ 12 } gap={ 1 } display={ 'flex' } justifyContent={ 'center' } alignItems={ 'center' }>
                <Button variant="contained" color='success' onClick={ setupPageType } >Save Details</Button>
            </Grid>
            ) }
            
        </Grid>
    )
})

export default PageDetails;