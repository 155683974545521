import {useContext} from 'react';
import AppContext from '../Context/AppContext';
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = (props) => {
  
  const appStates = useContext(AppContext);

  if( appStates.getToken() === null){
    console.log("Jumping Back To Login", appStates.getToken(), localStorage.getItem("token"));
    return (
      <Navigate to={"/login"} replace={true} />
    )
  }
  
  return (
    <>
    { props.children }
    </>
  )
}

export default ProtectedRoutes