import React, { useEffect, useState } from 'react';
import { useLocation, Link } from "react-router-dom";
import { Breadcrumbs, Button, Grid } from "@mui/material"
import { MdNavigateNext } from "react-icons/md";
import "../../Components.css";

const DynamicBreadCrumbs = ({ toggleDrawer }) => {

    const location = useLocation();
    const [links, setLinks] = useState([]);

    useEffect(() => {
        let items = location.pathname.split("/");
        const final_list = [];
        items.forEach(e => {
            let t = paths.find(it => it.pathReg.test(e));
            if (typeof t !== 'undefined') {
                if (t.link === false) {
                    final_list.push(
                        <Link 
                        onClick={ (event) => {
                            const t = window.confirm("Are you sure you want leave the page?");
                            if(!t){
                                event.preventDefault();
                            }
                        } }
                        key={ t.displayName } 
                        className='router_link'
                        underline='hover' 
                        to={ location.pathname }>
                            { t.displayName ? t.displayName : e }
                        </Link>)
                } else {
                    final_list.push(
                        <Link 
                            onClick={ (event) => {
                                const t = window.confirm("Are you sure you want leave the page?");
                                if(!t){
                                    event.preventDefault();
                                }
                            } }
                            key={ t.displayName } className='router_link' underline='hover' to={ t.link }>
                            { t.displayName ? t.displayName : e }
                        </Link>)
                }
            }
        })

        setLinks(final_list);

    }, [document.URL])

    const paths = [
        // { "pathReg" : /^home$/, "link": "/home", "displayName" : "Home" },
        { "pathReg": /^qc_page$/, "link": "/", "displayName": "QC Page" },
        { "pathReg": /^\d{4}$/, "link": "/" },
        { "pathReg": /^Bird_/, "link": false }
    ]

    return (
        <Grid container sx={{ narginTop:"2px", marginBottom:"2px" }}>
            <Grid container item sm={8} md={8} lg={8}>
                <Breadcrumbs aria-label="breadcrumb"
                    separator={ <MdNavigateNext fontSize={ 'small' } color='var(--accent-color-dark)' /> }
                    sx={ { paddingInline: "15px" } }
                >
                    <Link
                        onClick={ (event) => {
                            const t = window.confirm("Are you sure you want leave the page?");
                            if(!t){
                                event.preventDefault();
                            }
                        } }
                        underline='hover' 
                        className='router_link' 
                        to={ '/home' }>
                            Home
                    </Link>
                    { links.map(e => e) }
                </Breadcrumbs>
            </Grid>
            <Grid item sm={4} md={4} lg={4} 
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                paddingInline={'2rem'}
                gap={"15px"}
            >
                <Link to="/home/specieslist" className='router_link' target="_blank" rel="noopener noreferrer" >
                    Species List
                </Link>
                <Link to="/home/localitylist" className='router_link' target="_blank" rel="noopener noreferrer" >
                    Locality List
                </Link>
            </Grid>
        </Grid>

    )
}

export default DynamicBreadCrumbs