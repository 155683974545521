import { textRenderer, dropdownRenderer } from 'handsontable/renderers';
import { sexValidator, dateValidator, weightValidator, wingValidator, ringValidator, speciesCodeValidator, ageValidator, speciesValidator, localityValidator, moultValidator, plumageValidator, broodValidator, billValidator, tailValidator } from "../../../Utils/Validations/TableValidators"

function ringNoRenderer(instance, td, row, col, prop, value, cellProperties, errorList) {
    textRenderer.apply(this, arguments);
    if ( !ringValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function wingRender(instance, td, row, col, prop, value, cellProperties, errorList) {
    textRenderer.apply(this, arguments);
    if ( wingValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function WeightRender(instance, td, row, col, prop, value, cellProperties, errorList){
    textRenderer.apply(this,arguments);
    let cellMetas = instance.getDataAtRowProp(row, "RingSize");

    if ( cellMetas === "A" ){
        if ( value < 8 || value  > 80 ) {
            td.style.backgroundColor = "orange"
        }
    }
    if ( cellMetas === "AB" ){
        if ( value < 22 || value  > 135 ) {
            td.style.backgroundColor = "orange"
        }
    }
    if ( cellMetas === "B" ){
        if ( value < 17 || value  > 243 ) {
            td.style.backgroundColor = "orange"
        }
    }
    if ( cellMetas === "C" ){
        if ( value < 95 || value  > 1045 ) {
            td.style.backgroundColor = "orange"
        }
    }
    if ( cellMetas === "F" ){
        if ( value < 120 || value  > 1550 ) {
            td.style.backgroundColor = "orange"
        }
    }
    if ( cellMetas === "G" ){
        if ( value < 420 || value  > 1380 ) {
            td.style.backgroundColor = "orange"
        }
    }
    if ( cellMetas === "K" ){
        if ( value < 415 || value  > 3150 ) {
            td.style.backgroundColor = "orange"
        }
    }
    if ( cellMetas === "L" ){
        if ( value < 2110 || value  > 5030 ) {
            td.style.backgroundColor = "orange"
        }
    }
    if ( cellMetas === "Z" ){
        if ( value < 6 || value  > 32 ) {
            td.style.backgroundColor = "orange"
        }
    }
    
    if ( weightValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }

    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function DateRender(instance, td, row, col, prop, value, cellProperties, errorList){
    textRenderer.apply(this,arguments);
    if ( dateValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function SpeciesCodeRender(instance, td, row, col, prop, value, cellProperties, errorList){
    textRenderer.apply(this,arguments);
    if ( speciesCodeValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function LocalityRender(instance, td, row, col, prop, value, cellProperties, errorList){
    textRenderer.apply(this,arguments);
    if ( localityValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function SexRender(instance, td, row, col, prop, value, cellProperties, errorList){
    dropdownRenderer.apply(this, arguments);
    if ( !sexValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }

    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function ageRender( instance, td, row, col, prop, value, cellProperties, errorList){
    dropdownRenderer.apply(this, arguments);
    if( !ageValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function SpeciesRender( instance, td, row, col, prop, value, cellProperties, errorList ){
        textRenderer.apply(this, arguments);
        if (speciesValidator(value)) {
            td.style.backgroundColor = 'yellow';
        }

        if ( value != null ){
            if (value.split(" ").length < 2){
                td.style.backgroundColor = 'yellow';
            }
        }

        if (typeof errorObj !== 'undefined') {
            td.style.backgroundColor = 'orange';
        }

        if ( typeof errorList !== 'undefined' ){
            let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
            if ( mType ){
                td.style.backgroundColor = 'tomato';
            } 
        }
}

function moultRender(instance, td, row, col, prop, value, cellProperties, errorList) {
    textRenderer.apply(this, arguments);
    if ( moultValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function plumageRender(instance, td, row, col, prop, value, cellProperties, errorList) {
    textRenderer.apply(this, arguments);
    if ( plumageValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function broodRender(instance, td, row, col, prop, value, cellProperties, errorList) {
    textRenderer.apply(this, arguments);
    if ( broodValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function billRender(instance, td, row, col, prop, value, cellProperties, errorList) {
    textRenderer.apply(this, arguments);
    if ( billValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function tarsRender(instance, td, row, col, prop, value, cellProperties, errorList) {
    textRenderer.apply(this, arguments);
    if ( billValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

function tailRender(instance, td, row, col, prop, value, cellProperties, errorList) {
    textRenderer.apply(this, arguments);
    if ( tailValidator(value) ){
        td.style.backgroundColor = 'yellow';
    }
    if ( typeof errorList !== 'undefined' ){
        let mType = errorList.obj.map(e=>e.type==="mergeError").reduce((accumulator, currentVal) => accumulator || currentVal, false);
        if ( mType ){
            td.style.backgroundColor = 'tomato';
        } 
    }
}

export { SexRender, SpeciesCodeRender, DateRender, WeightRender, wingRender, ringNoRenderer, ageRender, SpeciesRender, LocalityRender, moultRender, plumageRender, broodRender, billRender, tarsRender, tailRender }