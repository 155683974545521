import React, { useContext, useState } from 'react'
import { CiBoxList } from "react-icons/ci";
import { ListItemIcon, List, ListItem, ListItemButton, ListItemText, Drawer, Box, Divider, ListSubheader, Collapse } from '@mui/material';
import { Link } from 'react-router-dom';
import AppContext from "../../../Context/AppContext";

import { MdExpandLess, MdExpandMore, MdOutlineOndemandVideo } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { IoStatsChartSharp, IoAnalytics, IoHelpCircleOutline, IoHelpCircle } from "react-icons/io5";
import { FaUsers, FaHome, FaFile, FaHistory, FaTools  } from "react-icons/fa";

const MyDrawer = ({ open, toggleDrawer }) => {

    const appStates = useContext(AppContext);
    const [adminCollapse, setAdminCollapse] = useState(false);
    const handleCollapseClick = (event) => {
        event.stopPropagation();
        setAdminCollapse(prevState => !prevState);
    }
    
    const lastFilesVisited = localStorage.getItem("lastVisited") ? JSON.parse(localStorage.getItem("lastVisited")) : []

    const alignItemsStyle = { display : "flex", alignItems : "center", flexDirection : "row", gap :"15px" }

    const DrawerList = (
        <Box
            sx={ { width: 250 } }
            role="presentation"
            onClick={ toggleDrawer(false) }
        >
            <List>
            <ListSubheader style={ { backgroundColor: "var(--background-color-secondary)" } } >
                <div style={alignItemsStyle}>
                <FaTools size={"1rem"} /> Options 
                </div>
            </ListSubheader>
                <ListItem>
                    <ListItemButton
                        component={ Link }
                        to={ "/home" }
                    >
                        <ListItemIcon><FaHome size={ '1rem' } /></ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton
                        component={ Link }
                        to={ "/home/specieslist" }
                        target="_blank" rel="noopener noreferrer"
                    >
                        <ListItemIcon><CiBoxList size={ '1rem' } /></ListItemIcon>
                        <ListItemText primary="Species List" />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton
                        component={ Link }
                        to={ "/home/localitylist" }
                        target="_blank" rel="noopener noreferrer"
                    >
                        <ListItemIcon><CiBoxList size={ '1rem' } /></ListItemIcon>
                        <ListItemText primary="Locality List" />
                    </ListItemButton>
                </ListItem>

                {
                    appStates.getAuthStat() === "Admin" &&
                    (<>
                        <Divider />
                        <ListItem>
                            <ListItemButton onClick={ handleCollapseClick }>
                                <ListItemIcon><RiAdminFill size={ '1rem' } /></ListItemIcon>
                                <ListItemText primary="Admin" />
                                { adminCollapse ? <MdExpandLess /> : <MdExpandMore /> }
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={ adminCollapse } timeout={ "auto" } unmountOnExit>
                            <List disablePadding>
                                <ListItem>
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        component={ Link }
                                        to={ "/home/admin" }
                                        target="_blank" rel="noopener noreferrer"
                                    >
                                        <ListItemIcon><IoStatsChartSharp size={ '1rem' } /></ListItemIcon>
                                        <ListItemText primary="Statistics" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        component={ Link }
                                        to={ "/home/admin/users" }
                                        target="_blank" rel="noopener noreferrer"
                                    >
                                        <ListItemIcon><FaUsers size={ '1rem' } /></ListItemIcon>
                                        <ListItemText primary="Users" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        component={ Link }
                                        to={ "/home/admin/mergeAnalysis" }
                                        target="_blank" rel="noopener noreferrer"
                                    >
                                        <ListItemIcon><IoAnalytics size={ '1rem' } /></ListItemIcon>
                                        <ListItemText primary="Merge Analytics" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        component={ Link }
                                        to={ "/home/admin/mergeConflicts" }
                                        target="_blank" rel="noopener noreferrer"
                                    >
                                        <ListItemIcon><IoAnalytics size={ '1rem' } /></ListItemIcon>
                                        <ListItemText primary="Merge Conflicts" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Collapse>
                    </>
                    )
                }

                <Divider />
                {/* Last Accessed Files */ }
                <ListSubheader style={ { backgroundColor: "var(--background-color-secondary)" } } >
                <div style={alignItemsStyle}>
                    <FaHistory size={"1rem"} /> Last Files Visited 
                </div>
                </ListSubheader>                
                    { lastFilesVisited.map(e => {

                    return (
                        <ListItem>
                            <ListItemButton
                                component={ Link }
                                to={ "/" + e.filePage + "/" + e.fileYear + "/" + e.fileName + "/" + e.fileStatus + "/" + e.fileType }
                                target="_blank" rel="noopener noreferrer"
                            >
                                <ListItemIcon><FaFile size={ '1rem' } /></ListItemIcon>
                                <ListItemText primary={ "File" } secondary={ e.fileName } secondaryTypographyProps={{fontSize:"12px"}}/>
                            </ListItemButton>
                        </ListItem>
                    )

                }) }

                <Divider />
                <ListSubheader style={ { backgroundColor : "var(--background-color-secondary)" } }>
                <div style={alignItemsStyle}>
                    <IoHelpCircle size={"1rem"}/>
                    Help 
                </div>
                </ListSubheader>
                <ListItem>
                    <ListItemButton 
                        component={ Link }
                        to={"https://sites.google.com/bnhs.org/qcprocess"}
                        target="_blank" rel="noopener noreferrer"
                    >
                        <ListItemIcon><IoHelpCircleOutline size={"1rem"} /></ListItemIcon>
                        <ListItemText 
                            primary={"Documentation"} 
                            secondary={"Typed Documentation for QC working"}
                            secondaryTypographyProps={{fontSize:"14px"}}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton 
                        component={ Link }
                        to={"https://drive.google.com/file/d/1_H9cmnDkuIJwBlw2oYkScIlxk5gorc_J/view?usp=sharing"}
                        target="_blank" rel="noopener noreferrer"
                    >
                        <ListItemIcon><MdOutlineOndemandVideo size={"1rem"} /></ListItemIcon>
                        <ListItemText 
                            primary={"Tutorial"} 
                            secondary={"Video Tutorial for QC working"}
                            secondaryTypographyProps={{fontSize:"14px"}}
                        />
                    </ListItemButton>
                </ListItem>


            </List>
        </Box>
    );

    const style = {
        backgroundColor: "var(--background-color-primary)"
    }

    return (
        <>
            <Drawer
                open={ open }
                onClose={ toggleDrawer(false) }
                PaperProps={ {
                    sx: style
                } }
            >
                { DrawerList }
            </Drawer>
        </>
    )
}

export default MyDrawer