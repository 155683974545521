import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Home.css"
import { FormControl, InputLabel, Select, MenuItem, Snackbar, Alert, Button } from "@mui/material"
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import LoadingWrapper from "../Loading/LoadingWrapper";

import AppContext from '../../Context/AppContext';

import useFetch from "../../useHook/useFetch";
import CreateGetFetch from '../../ServerCalls/CreateGetFetch';
import CreatePostFetch from '../../ServerCalls/CreatePostFetch';

const Normal = () => {

    const navigate = useNavigate()
    const [PagesData, setPagesData] = useState([])
    const [AgHeaders, setAgHeaders] = useState([]);
    const [year, setYear] = useState("None");
    const selectedYear = useRef("None");
    const [listOfYear, setLYear] = useState([]);
    const [[snackBarVal, snackBarMsg, snackBarType], setSnackBar] = useState([false, "", "info"]);

    const { data: ListofYears, loading: LoadingYears } = useFetch("/getPossibleYear")
    const orderOfStatus = ['Processed', 'QC Ongoing', "QC_Done", "Added", "Extracted", "Unuseable_Page", 'Not Added']

    const getterFunc = CreateGetFetch();
    const postFunc = CreatePostFetch();

    const appStates = useContext(AppContext);

    const sortLogic = (a, b) => {
        const indexA = orderOfStatus.indexOf(a.status);
        const indexB = orderOfStatus.indexOf(b.status);
        if (indexA === -1 && indexB === -1) {
            return 0; // If both are not in the order array, keep their relative order
        } else if (indexA === -1) {
            return 1; // Move a to the bottom
        } else if (indexB === -1) {
            return -1; // Move b to the bottom
        }

        // Otherwise, compare their positions in the order array
        return indexA - indexB;
    }

    const selectYear = async (event) => {
        setYear(event);
        sessionStorage.setItem("lastSelectedYear", event);
        selectedYear.current = event;

        if ( selectedYear.current !== null && selectedYear.current !== "None" ){
            let response = await getterFunc.caller("/getPages?year=" + event);
            if (response.status === 200) {
                let data = await response.json()
    
                if (appStates.getAuthStat() !== "Admin") {
                    let filteredData = await data.data.filter(
                        e => e.status === "Processed" || e.status === "QC Ongoing" || e.status === "QC_Done"
                    )
                    filteredData.sort(sortLogic);
                    setPagesData(filteredData)
                } else {
                    data.data.sort(sortLogic)
                    setPagesData(data.data)
                }
            } else {
                setSnackBar([true, response.status + " : " + response.statusText, 'error'])
            }
        }

    }

    useEffect(() => {
        let year = sessionStorage.getItem("lastSelectedYear");
        if (year !== null) { selectYear(year); selectedYear.current = year; }

        if (appStates.getAuthStat() === 'Admin') {
            let admin_AgHeaders = [
                {
                    field: 'fileName',
                    editable: false,
                    filter: true,
                    flex: 1,
                    cellClass: "hyperlink",
                    onCellClicked: (event) => { handleImagePage(event.data) },
                }, {
                    field: 'status',
                    editable: false,
                    filter: true,
                }, {
                    field: "reprocess",
                    headerName: "Reprocess",
                    filter: false,
                    editable: false,
                    sortable: false,
                    cellRenderer: (param) => {

                        if (
                            param.data.status === "Processed" || 
                            param.data.status === "QC Ongoing" || 
                            param.data.status === "QC_Done"
                        ) {
                            return (
                                <Button
                                    variant="contained"
                                    size='small'
                                    color='success'
                                    onClick={ (event) =>
                                        callServer(param.data) }>
                                    Reprocess
                                </Button>
                            )
                        } else {
                            return (
                                <></>
                            )
                        }
                    }
                }, {
                    field: 'action',
                    headerName: 'Action',
                    filter: false,
                    editable: false,
                    sortable: false,
                    cellRenderer: (param) => {

                        if (param.data.status === "Processed") {
                            return (
                                <Button
                                    variant="contained"
                                    size='small'
                                    color='success'
                                    onClick={ (event) => handleFileSelect(param.data) } >QC</Button>
                            )
                        }

                        if (param.data.status === "QC Ongoing") {
                            return (
                                <Button
                                    variant="contained"
                                    size='small'
                                    color='success'
                                    onClick={ (event) => handleRevistSelect(param.data) } >
                                    Revisit
                                </Button>
                            )
                        }

                        if (param.data.status === "QC Done") {
                            return (
                                <Button
                                    variant="contained"
                                    size='small'
                                    color='success'
                                    onClick={ (event) => handleRevistSelect(param.data) } >
                                    Revisit
                                </Button>
                            )
                        }

                        if ( param.data.status === "Merged"){
                            return (
                                <Button
                                    variant="contained"
                                    size='small'
                                    color='success'
                                    onClick={ (event) => handleRevistSelect(param.data) } >
                                    Revisit
                                </Button>
                            )
                        }

                        return (
                            <></>
                        )
                    },
                },
            ]
            setAgHeaders(admin_AgHeaders)
        }
        else {
            let qc_AgHeaders = [
                {
                    field: 'fileName',
                    editable: false,
                    filter: true,
                    flex: 1,
                    cellClass: "hyperlink",
                    onCellClicked: (event) => { handleImagePage(event.data) },
                }, {
                    field: 'status',
                    editable: false,
                    filter: true,
                }, {
                    field: 'action',
                    headerName: 'Action',
                    filter: false,
                    editable: false,
                    sortable: false,
                    cellRenderer: (param) => {

                        if (param.data.status === "Processed") {
                            return (
                                <Button variant="contained"
                                size='small'
                                color='success' onClick={ (event) => handleFileSelect(param.data) } >QC</Button>
                            )
                        }

                        if (param.data.status === "QC Ongoing") {
                            return (
                                <Button variant="contained"
                                size='small'
                                color='success' onClick={ (event) => handleRevistSelect(param.data) } >
                                    Revisit
                                </Button>
                            )
                        }

                        if (param.data.status === "QC Done") {
                            return (
                                <Button variant="contained"
                                size='small'
                                color='success' onClick={ (event) => handleRevistSelect(param.data) } >
                                    Revisit
                                </Button>
                            )
                        }
                        return (
                            <></>
                        )
                    },
                },
            ]
            setAgHeaders(qc_AgHeaders)
        }
    }, [])

    useEffect(() => {
        if (typeof ListofYears !== 'undefined') {
            setLYear(ListofYears);
        }
    }, [LoadingYears, ListofYears])

    const handleFileSelect = (e) => {
        navigate("/qc_page/" + selectedYear.current + "/" + e.fileName + "/" + e.status.replace(" ", "_") + "/getTable")
    }

    const handleRevistSelect = (e) => {
        
        navigate
        ("/qc_revisit_page/" + selectedYear.current + "/" + e.fileName + "/" + e.status.replace(" ", "_") + "/getQCTable");
    }

    const handleImagePage = (e) => {

        if (appStates.getAuthStat() === "Admin"){
            navigate("/image_page/" + selectedYear.current + "/" + e.fileName);
        }
    }

    const callServer = async (e) => {
        let payload = new FormData();
        payload.append("fileName", e.fileName);
        payload.append("status", e.status);
        payload.append("fileYear", selectedYear.current);
        let response = await postFunc.caller("/performOperation", payload);
        if (response.status === 200) {
            setSnackBar([true, "Server Accepts Request. Procedure may take time.", "success"]);
            if( selectedYear.current != 'None' ){
                let response = await getterFunc.caller("/getPages?year=" + selectedYear.current);
                let data = await response.json()
    
                if (appStates.getAuthStat() !== "Admin") {
                    let filterData = await data.data.filter(e => e.status === "Processed" || e.status === "QC Ongoing" || e.status === "QC_Done")
                    filterData.sort(sortLogic);
                    setPagesData(filterData)
                } else {
                    data.data.sort(sortLogic);
                    setPagesData(data.data);
                }
            }
        } else {
            setSnackBar([true, response.status + " : " + response.statusText, "error"])
        }
    }

    return (
        <>
            <div className='year_select_div'>
                <FormControl 
                    fullWidth 
                    sx={ { 
                        minWidth: "60vw", 
                        color:"var(--font-color-primary)",

                    } } 
                    focused>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            color : "var(--font-color-primary)",
                            "&.Mui-focused":{
                                color:"var(--font-color-primary)"
                            }
                        }}
                    >
                        Year
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ year }
                        label="Year"
                        onChange={ (value) => { selectYear(value.target.value) } }
                        sx={ {
                            color: "var(--font-color-primary)",
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--accent-color)',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--accent-color)',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--accent-color)',
                            },
                            '.MuiSvgIcon-root ': {
                                fill: "var(--accent-color) !important",
                            },
                            ".MuiSelect-root" : {
                                color : "var(--font-color-primary)"
                            }
                        } }
                        MenuProps={ { PaperProps: { sx: { maxHeight: 500 } } } }
                    >
                        <MenuItem value={ "None" }>None</MenuItem>
                        {
                            listOfYear.map(e =>
                                <MenuItem key={ e } value={ e } >
                                    { e }
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </div>
            <div className='table_Container' style={ { width: '60vw' } }>

                <div
                    className="ag-theme-quartz" // applying the grid theme
                    style={ { height: "65vh", flex: 1 } } // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={ PagesData }
                        defaultColDef={ {
                            cellDataType: 'text'
                        } }
                        tooltipInteraction={ true }
                        columnDefs={ AgHeaders }
                    />
                </div>
            </div>
            
            { LoadingYears && ( <LoadingWrapper /> ) }
            { getterFunc.loading && ( <LoadingWrapper /> )  }
            { postFunc.loading && ( <LoadingWrapper /> ) }

            <Snackbar
                open={ snackBarVal }
                key={ "SnackBar" }
                autoHideDuration={ 1000 }
                onClick={ () => setSnackBar(prevState => [false, prevState[1], prevState[2]]) }
            >
                <Alert
                    onClose={ () => setSnackBar([false, snackBarMsg, snackBarType]) }
                    severity={ snackBarType }
                    variant="filled"
                    sx={ { width: '100%' } }
                >
                    { snackBarMsg }
                </Alert>
            </Snackbar>
        </>)
}

export default Normal