import React, { useEffect, useState, forwardRef, useContext } from 'react';
import { HotTable } from '@handsontable/react';
import { SexRender, SpeciesCodeRender, DateRender, WeightRender, wingRender, ringNoRenderer, ageRender, SpeciesRender, LocalityRender, moultRender, plumageRender, broodRender, billRender, tarsRender, tailRender } from "./HandOnRender";
import AppContext from '../../../Context/AppContext';
import CacheManger from "../../../Utils/CacheManage";
import CreateGetFetch from "../../../ServerCalls/CreateGetFetch";

const HandsOnTable = forwardRef((
    { 
        TableData = [], 
        delRowsCallback = ()=>{ console.log("NoParam"); },
        updatedRowsCallBack = () => { console.log("NoParam"); }
    }, passedRef) => {

    const [cols, setCols] = useState([]);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState([]);

    const cacheManager = new CacheManger();
    const getObject = CreateGetFetch();

    const columnListBuilder = async (list) => {
        if (typeof list !== 'undefined') {
            const fixedList = {
                "id": {
                    data: "id",
                    type: "text",
                    fixedColumnsStart: true
                },
                "Sex": {
                    data: "Sex",
                    type: "dropdown",
                    source: ["", "Male", "Female", "Unknown"],
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            SexRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "RingSize": {
                    data: "RingSize",
                    type: "dropdown",
                    source: ["A", "AB", "B", "C", "F", "AA", "BC", "G", "K", "L", "M", "YZ", "Z", "NA"]
                },
                "RingNo": {
                    data: "RingNo",
                    type: "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) =>{
                        ringNoRenderer(instance, td, row, col, prop, value, cellProperties, 
                            TableData.errorList[''+row+'_'+prop])
                    },
                },
                "Species": {
                    data: "Species",
                    type: "text",
                    renderer:  (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            SpeciesRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },

                },
                "Wing": {
                    data: "Wing",
                    type: "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            wingRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "Weight": {
                    data: "Weight",
                    type: "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            WeightRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "Date": {
                    data: "Date",
                    type: "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            DateRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "SpeciesCode": {
                    data: "SpeciesCode",
                    type: "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            SpeciesCodeRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "Locality" : {
                    data: "Locality",
                    type: "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            LocalityRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "Leg": {
                    data: "Leg",
                    type: "dropdown",
                    source: ["", "Left Leg", "Right Leg"],
                },
                "Ringer": {
                    data: "Ringer",
                    type: "text",
                },
                "Age": {
                    data: "Age",
                    type: "dropdown",
                    source: ["","AD", "IMM", "JUV", "FG", "FW", "1Y"],
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            ageRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "Moult" : {
                    data : "Moult",
                    type : "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            moultRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "Plumage" : {
                    data : "Plumage",
                    type : "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            plumageRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "Brood" : {
                    data : "Brood",
                    type : "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            broodRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "Bill" : {
                    data : "Bill",
                    type : "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            billRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "Tars" : {
                    data : "Tars",
                    type : "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            tarsRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
                "Tail" : {
                    data : "Tail",
                    type : "text",
                    renderer: (instance, td, row, col, prop, value, cellProperties) => 
                        {
                            tailRender(instance, td, row, col, prop, value, cellProperties, TableData.errorList[''+row+'_'+prop])
                        },
                },
            }

            const orderIndex = ["id", "SpeciesCode", "RingNo", "Species"];
            list.sort((a, b) => {
                const indexA = orderIndex.indexOf(a);
                const indexB = orderIndex.indexOf(b);

                // If one of the names is not in the orderIndex array, move it to the bottom
                if (indexA === -1 && indexB === -1) {
                    return 0; // If both are not in the order array, keep their relative order
                } else if (indexA === -1) {
                    return 1; // Move a to the bottom
                } else if (indexB === -1) {
                    return -1; // Move b to the bottom
                }

                // Otherwise, compare their positions in the order array
                return indexA - indexB;
            })

            const t = await list.map(e => {
                if (typeof fixedList[e] !== 'undefined') {
                    return fixedList[e];
                } 
                else if ( e === "database_id" ){
                    return {
                        data : e, 
                        type:"text", 
                        name: e, 
                        readOnly : true,
                    }
                }
                else if ( e === 'valid' ){
                    return {
                        data : e,
                        type : "checkbox",
                        name : e,
                        readOnly : true
                    }
                }
                else {
                    return {
                        data: e,
                        type: "text",
                        name : e
                    }
                }
            });

            if (!TableData.headers.includes("Leg")) {
                t.push({
                    data: "Leg",
                    type: "dropdown",
                    source: ["", "Left Leg", "Right Leg"],
                })
                TableData.headers.push("Leg")
            }

            if (!TableData.headers.includes("Ringer")) {
                t.push({
                    data: "Ringer",
                    type: "text",
                });
                TableData.headers.push("Ringer");
            }

            setCols(t);
        }

    }

    const appStates = useContext(AppContext);

    const updateErrorList = () => {
        let temp = []
        for (const [key, value] of Object.entries(TableData.errorList)) {

            let message = value.obj.map(e => e.message + ", ").join();

            if (!(parseInt(value.row) < 0) && (typeof TableData.headers.indexOf(value.col) != 'undefined')) {
                temp.push({
                    col: TableData.headers.indexOf(value.col),
                    row: value.row,
                    comment: { value: message }
                })
            }
        }
        setError(temp);
    }

    useEffect(() => {

        const list = TableData.headers;
        columnListBuilder(list);

        if (typeof TableData.data !== 'undefined') { setRows(TableData.data) }

        if (typeof TableData.errorList !== 'undefined' && typeof TableData.headers !== 'undefined') {

            updateErrorList();
        }

    }, [TableData])

    const cellEditedCallback = (param) => {
        let arr = param?.pop();
        if (typeof arr !== 'undefined') {
            delete (TableData.errorList["" + arr[0] + "_" + arr[1]]);
            updatedRowsCallBack(arr[0]);
            updateErrorList();
        }
    }

    const afterAutoFill = (param) => {
        console.log(param)
    }

    const handleRingNoCall = (key, selection, clickEvent) => {
        const hot = passedRef.current.hotInstance;
        let input = window.prompt("Enter Start of Ring number sequence");
        const numberPattern = new RegExp('[0-9.]+');
        if (numberPattern.test(input)) {
            const colIndex = cols.map(e => e.data).indexOf("RingNo")
            const numberOfRows = hot.countRows();

            let currentNumber = parseInt(input);
            for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex = rowIndex + 1) {
                hot.setDataAtCell(rowIndex, colIndex, currentNumber);
                currentNumber = currentNumber + 1;
            }
        } else {
            window.alert("Not a Number")
        }
    }

    const handleColAdd = (key, selection, clickEvent) => {
        const colName = prompt("Enter Column Name");
        
        // Checking if Column Already Exists
        const exists = cols.map(e=>e.data).find(e=>e.toLowerCase() === colName.toLowerCase());

        if ( typeof exists !== 'undefined' ){
            window.alert("Column Name Already Exists")
        }else{

            const tableHeaders = passedRef.current.hotInstance.getColHeader();
            const arrayOfarray = passedRef.current.hotInstance.getData()
            let data = arrayOfarray.map(e => {
              let obj = {};
              tableHeaders.forEach((key, index) => {
                obj[key] = e[index];
              });
              if (obj["RingNo"] !== null) {
                return obj;
              }
            })
            data = data.filter(e => typeof e !== 'undefined');
            
            const alreadyThere = TableData.headers.find(e => e.toLowerCase() === colName.toLowerCase());
            if ( typeof alreadyThere !== 'undefined'){
                data = data.map((e, index)=> { 
                    console.log(TableData.data[index][colName]) ;
                    return { ...e, [colName] : TableData.data[index][colName] }} 
                );
                columnListBuilder( [ ...cols.map(e=>e.data), colName ] )
                setRows(data);
            }else{
                data = data.map(e=> { return { ...e, [colName] : "" }} );
                columnListBuilder( [ ...cols.map(e=>e.data), colName ] )
                setRows(data);
            }

        }

    }

    const handleColDelete = ( key, selection, clickEvent ) => {
        clickEvent.preventDefault();

        const colName = prompt("Alert this operation removes data from orginal table and cannot be changed,Enter Column Name:");
        
        // Checking if Column Already Exists
        const exists = cols.map(e=>e.data).find(e=>e.toLowerCase() === colName.toLowerCase());

        if ( typeof exists === 'undefined' ){
            window.alert("Column Name Does Not Exists")
        }else{

            const tableHeaders = passedRef.current.hotInstance.getColHeader();
            const arrayOfarray = passedRef.current.hotInstance.getData()
            let data = arrayOfarray.map(e => {
              let obj = {};
              tableHeaders.forEach((key, index) => {
                obj[key] = e[index];
              });
              if (obj["RingNo"] !== null) {
                return obj;
              }
            })
            data = data.filter(e => typeof e !== 'undefined');
            
            data = data.map(e=> { return { ...e }} );
            columnListBuilder( [ ...cols.map(e=>e.data).filter(e=>e !== colName) ] )
            console.log(data);
            setRows(data);
        }
    }

    const handleCreateNewRow = (index,  number) => {
        passedRef.current.hotInstance.setDataAtRowProp(index, 'id', -1);
        if( TableData.headers.includes("database_id") ){
            passedRef.current.hotInstance.setDataAtRowProp(index,'database_id', -1);
        }
    }

    const handleDelRows = (index, amount, array) => {
        if( TableData.headers.includes("database_id") ){
            let id = passedRef.current.hotInstance.getDataAtRowProp(index, "database_id");
            if ( id !== -1) delRowsCallback(id)
            
        }
    }

    const handleSpeciesNameAutoFill = async (key, selection, clickEvent) => {

        let performAction = window.confirm("This action will overwrite the Species column? Do you wish to perform the action?");

        if ( performAction){

            let mappingData = {}
            if ( cacheManager.doesExists("speciesMap")){
                mappingData = cacheManager.getEntry("speciesMap")
            }else{
                let res = await getObject.caller("/getSpeciesTable");
                let _data = await res.json();
                mappingData = _data;
                cacheManager.setEntry("speciesMap", _data);
            }
            mappingData = mappingData.data;
            let map = {};
            mappingData.forEach(e => {
                map[
                    e["Species Code"]+"-"+e["Race Code"]
                ] = 
                    e["Species Name"] + '' + ( e["Race Name"] === "Nil (no race)" ? "" :  ( " " + e["Race Name"]))
                    
                map[e["Species Code"]] = e["Species Name"] 
                map[e["Species Code"]+"-0"] = e["Species Name"] 
            })
            
            const hotInstance = passedRef.current.hotInstance;
            const arr = hotInstance.getDataAtProp("SpeciesCode")
            arr.forEach((e,indx) => {
                
                if ( e.trim().length !== 0 ){
                    let parame = e.replace(/^0+(?=[1-9])/, '').trim();
                    if ( /^[0-9]{1,4}-[0-9]{1}$/.test(parame) ){
                        hotInstance.setDataAtRowProp(indx, "Species", map[parame])
                    }
                }
                
            })
        }
    }

    return (
        <>
            <HotTable
                ref={ passedRef }
                data={ rows }
                colHeaders={ cols.map(e=>e.data) }
                columns={ cols }
                width={ "100%" }
                height={ "100vh" }
                autoWrapRow={ true }
                autoWrapCol={ true }
                autoColumnSize={ true }
                dropdownMenu={ true }
                multiColumnSorting={ true }
                manualColumnFreeze={ true }
                filters={ true }
                manualColumnResize={ true }
                comments={ true }
                cell={ error }
                afterChange={ cellEditedCallback }
                afterAutofill={ afterAutoFill }
                fixedColumnsStart={ TableData.headers.includes("SpeciesCode") ? 2 : 1 }

                allowInsertColumn={ appStates.getAuthStat() === "Admin" }
                afterCreateRow={ handleCreateNewRow }
                allowInsertRow={ appStates.getAuthStat() === "Admin" }
                allowRemoveColumn={ appStates.getAuthStat() === "Admin" }
                allowRemoveRow={ appStates.getAuthStat() === "Admin" }
                beforeRemoveRow={ handleDelRows }

                contextMenu={ {

                    items: {
                        row_above: {
                            disabled() { return !(appStates.getAuthStat() === "Admin") }
                        },
                        row_below: {
                            disabled() { return !(appStates.getAuthStat() === "Admin") }
                        },
                        col_addition: {
                            name : "Add Column",
                            disabled() { return !(appStates.getAuthStat() === "Admin") },
                            callback(key, selection, clickEvent){
                                clickEvent.preventDefault();
                                handleColAdd(key, selection, clickEvent);
                            }
                        },
                        remove_row: {
                            disabled() { return !(appStates.getAuthStat() === "Admin") }
                        },
                        col_removal: {
                            name : "Remove Column",
                            disabled() { return !(appStates.getAuthStat() === "Admin") },
                            callback(key, selection, clickEvent){
                                handleColDelete(key, selection, clickEvent);
                            }
                        },
                        sp1: "---------",
                        undo: {},
                        redo: {},
                        sp2: "---------",
                        freeze_column: {},
                        unfreeze_column: {},
                        sp3: "---------",
                        addFix: {
                            name() { return '<b>Reset RingNo Sequence</b>' },
                            callback(key, selection, clickEvent) {
                                clickEvent.preventDefault();
                                handleRingNoCall(key, selection, clickEvent)
                            },
                            hidden() {
                                let hot = passedRef.current.hotInstance;
                                let ringnoIndex = cols.map(e => e.data).indexOf("RingNo")
                                let lastCell = hot.getSelectedLast();
                                return lastCell[1] !== ringnoIndex;
                            }
                        },
                        autoSpeciesName : {
                            name() {return '<b>Auto Fill SpeciesName</b>'},
                            callback(key, selection, clickEvent){
                                clickEvent.preventDefault();
                                handleSpeciesNameAutoFill(key, selection, clickEvent);
                            },
                            hidden() {
                                let hot = passedRef.current.hotInstance;
                                let spIndex = cols.map(e=>e.data).indexOf("Species");
                                let lastCell = hot.getSelectedLast();
                                return lastCell[1] !== spIndex;
                            }
                        }
                    }

                } }

                licenseKey="non-commercial-and-evaluation"
            >

            </HotTable>
        </>
    )
})


export default HandsOnTable