
/**
 * @name RetriveDataFromTable
 * @param {React.RefObject} _TableRef : Reference to Table
 * 
 * Function uses HandsOnTable Hooks and API to get all the data and form a array of object
 * 
 * @returns [objects]
 */
const RetriveDataFromTable = (_TableRef) => {
    if (_TableRef !== null) {
        // Converting Array of Array to Array of Objects
        const tableHeaders = _TableRef.current.hotInstance.getColHeader();
        const arrayOfarray = _TableRef.current.hotInstance.getData()
        let data = arrayOfarray.map(e => {
            let obj = {};
            tableHeaders.forEach((key, index) => {
                obj[key] = e[index];
            });
            if (obj["RingNo"] !== null) {
                return obj;
            }
        })
        data = data.filter(e => typeof e !== 'undefined');
        return data;
    }
}

export { RetriveDataFromTable };