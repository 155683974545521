import React from 'react';
import ReadOnlyTable from '../../../Component/DataGrids/AGGrid/ReadOnlyTable';
import "../Admin.css"
import useFetch from '../../../useHook/useFetch';
import {Button} from "@mui/material"
import DownloadCSV from "../../../ServerCalls/DownloadCSV"

const UserAccount = ({ param }) => {
    return ( 

        <UserPanel />

    )
}

const UserPanel = () => {

    const { data, loading, error } = useFetch("/getMonitoringDetails");

    const downloadCallerObj = DownloadCSV();
    const handleDownloadClick = () =>{
        downloadCallerObj.caller("/downloadUserHistory")
    };

    return ( 
        <>
            <Button variant='contained' color='success' onClick={handleDownloadClick}>
                Download CSV
            </Button>
            <ReadOnlyTable 
                TableData={data}
            />
        </>
    )
}

export default UserAccount