import { useEffect, useState, useContext } from "react";
import AppContext from "../Context/AppContext";

function useFetch(url) {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const BASE_URL_LOC = process.env["REACT_APP_SERVER_LOCATION"];

    const appState = useContext(AppContext);

    useEffect(() =>{
        caller()
    },[url])

    const caller = async () =>{
        setLoading(true);
        fetch( BASE_URL_LOC+url, {
            method: "GET",
            headers: {
                'authentication-token' : appState.getToken(),
                'Access-Control-Allow-Origin': '*',
            },
            redirect: "follow",
        })
            .then(async (response) => {
                if (response.status === 200) {
                    const data = await response.json()
                    setData(data);
                    setError(null);
                    setLoading(false);
                } else {

                    if ( response.status === 401 ){
                        let txt = await response.text();
                        if ( txt.includes("Token is invalid") ){
                            console.log("Logging out user")
                            localStorage.removeItem("userToken")
                            localStorage.removeItem("token")
                            window.location.reload()
                        }
                    }

                    setData([]);
                    setError("Error Occured \n Status Code:" + response.status);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setError(err.message);
                setLoading(false)
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const refresh = () =>{
        caller();
    }

    return { data, setData,loading, error, refresh};
}

export default useFetch;