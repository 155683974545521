import React, { forwardRef, useImperativeHandle, useState } from 'react';
import CustomTextField from '../../MuiComponent/TextField/CustomTextField';
import { Button,Alert, Snackbar } from "@mui/material";
import CreatePostFetch from '../../../ServerCalls/CreatePostFetch';

import { useForm, Controller } from "react-hook-form"
import CacheManager from '../../../Utils/CacheManage';

const SpeciesUpsert = forwardRef((props, _passedRef) => {

    const [[snackBarVal, snackBarMsg, snackBarType], setSnackBar] = useState([false, "", "info"]);

    const postBuilder = CreatePostFetch();
    const cacheManager = new CacheManager();

    const { 
        control,
        handleSubmit,
        formState : {errors},
        setValue
    } = useForm({
        defaultValues:{
            speciesCode : '',
            speciesName : '',
            raceCode : "",
            raceName : ""
        }
    });

    useImperativeHandle(_passedRef, ()=>({
        setFormValues : handleValueChange
    }))

    const handleValueChange = (spCode, spName, raceCode=null, raceName=null) =>{
        setValue("speciesName" , spName,{ shouldValidate : true });
        setValue("speciesCode" , spCode,{ shouldValidate : true });
        setValue("raceCode" , raceCode,{ shouldValidate : true });
        setValue("raceName" , raceName,{ shouldValidate : true });
    }

    const handleUpsertCall = async (data) => {
        cacheManager.removeEntry("speciesMap")
        let formBody = new FormData();
        for ( var key in data){

            if ( typeof data[key] === "number"){
                if ( data[key] >= 1 ){
                    formBody.append(key, data[key]);
                }else{
                    formBody.append(key,"null");
                }
            }else {
                if ( data[key].trim().length === 0 ){
                    formBody.append(key, "null");
                }else{
                    formBody.append( key , data[key]);
                }
            }

        }

        let response = await postBuilder.caller("/upsertSpeciesData", formBody);

        if(response.status === 200){
            setSnackBar([true, "Species Record Upserted Successfully", "success"]);
        }
        else{
            setSnackBar([true, "Operation Failed", "error"]);
        }
    }

    return (
        <div className='user_crud-frame'>
            <div className='user_crud-card'>
                <div className='user_operation_container'>
                    <div className='user-operation_label'>
                        Edit/Add Species Entry
                    </div>
                    <Controller
                        name='speciesCode'
                        control={control}
                        rules={{
                            required: {
                                value : true,
                                message : "*Required"
                            },
                            pattern : {
                                value : /^[0-9]+$/,
                                message : "No Alpha Chars or Species Char Allowed"
                            }
                        }}
                        render={({field}) => (
                            <CustomTextField
                                fullWidth
                                type='text'
                                label="Enter Species Code"
                                {...field}
                                InputLabelProps={{ shrink: field.value? true : false }} 
                                error={errors.speciesCode? true : false}
                                helperText={errors.speciesCode? errors.speciesCode.message : ""}
                                
                            />
                        )}
                    />

                    <Controller
                        name='speciesName'
                        control={control}
                        rules={{ 
                            pattern : {
                                value : /^[a-zA-Z ]+$/,
                                message : "No Special Chars or Numerical Chars Allowed"
                            },
                            minLength : {
                                value : 2,
                                message : "Insuffcient Length"
                            }  
                        }}
                        render={({field}) => (
                            <CustomTextField
                                fullWidth
                                label="Enter Species Name"
                                type='text'
                                {...field}
                                InputLabelProps={{ shrink: field.value? true : false }} 
                                error={errors.speciesName? true : false}
                                helperText={errors.speciesName ? errors.speciesName.message : ""}
                            />
                        )}
                        />

                    <Controller
                        name='raceCode'
                        control={control}
                        rules={{
                            pattern : {
                                value : /^[0-9]+$/,
                                message : "No Alpha Chars or Species Char Allowed"
                            }
                        }}
                        render={({field})=>(
                            <CustomTextField
                                fullWidth
                                label="EnterRace Code"
                                type='text'
                                {...field}
                                InputLabelProps={{ shrink: field.value? true : false }} 
                                error={errors.raceCode? true : false}
                                helperText={errors.raceCode ? errors.raceCode.message : ""}
                            />
                        )}
                        />

                    <Controller 
                        name='raceName'
                        control={control}
                        rules={{ 
                            pattern : {
                                value : /^[a-zA-Z ()]+$/,
                                message : "No Special Chars or Numerical Chars Allowed"
                            },
                            minLength : {
                                value : 2,
                                message : "Insuffcient Length"
                            }  
                        }}
                        render={({field})=>(
                            <CustomTextField
                                fullWidth
                                label="Enter Race Name"
                                type='text'
                                {...field}
                                InputLabelProps={{ shrink: field.value? true : false }} 
                                error={errors.raceName? true : false}
                                helperText={errors.raceName ? errors.raceName.message : ""}
                            />
                        )}
                        />
                    <Button
                        variant="contained"
                        size='small'
                        color='success'
                        onClick={ handleSubmit(handleUpsertCall) }
                    >
                        Confirm
                    </Button>
                </div>
                <hr className='user_operations_divider' />
            </div>

            <Snackbar
                open={ snackBarVal }
                key={ "SnackBar" }
                autoHideDuration={ 1000 }
                onClose={ () => { setSnackBar(prevState => [false, prevState[1], prevState[2]]) } }
            >
                <Alert
                    onClose={ () => setSnackBar([false, snackBarMsg, snackBarType]) }
                    severity={ snackBarType }
                    variant="filled"
                    sx={ { width: '100%' } }
                >
                    { snackBarMsg }
                </Alert>
            </Snackbar>
        </div>
    )
})

export default SpeciesUpsert