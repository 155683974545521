import React from 'react';
import { Grid } from '@mui/material';

const FatalErrorCard = ({ object = {} }) => {

    const styles = {
        cardContainer: {
            padding: "1%",
            border: "solid",
            borderRadius: "5px",
            borderColor: "var(--secondary-color)",
            background: "var(--background-color-secondary)",
            paddingBottom: "5px",
            paddingInline: "10px",
            overflow: "hidden",
            minHeight : "155px"
        },
        rowColIndication: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            color: "var(--fontColorPrimaryLight)",
            paddingBottom: "5px"
        },
        messageStyle: {
            paddingBottom: "15px",
            textWrap: "pretty"
        },
        itemUniqueStle : {
            textWrap : "nowrap",
            flex : "0.5"
        }
    }

    return (
        <Grid item sm={ 12 } md={ 6 } lg={ 3 }>
            <div key={ object.type + object.row } style={ styles.cardContainer } >
                <div style={ styles.rowColIndication }>
                    <span style={styles.itemUniqueStle}>Row: { object.row }</span>
                    <span style={styles.itemUniqueStle}>Col: { object.col }</span>
                </div>
                <div style={styles.messageStyle} >Message : { object.message }</div>
            </div>
        </Grid>
    )
}

export default FatalErrorCard