import { useState, useContext } from "react";
import AppContext from "../Context/AppContext";

function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

const DownloadCSV = () => {

    const BASE_URL_LOC = process.env["REACT_APP_SERVER_LOCATION"];
    const [loading, setLoading] = useState(false);

    const appState = useContext(AppContext);

    const caller = (url) => {
        setLoading(false);
        fetch(BASE_URL_LOC + url, {
            method: "GET",
            redirect: "follow",
            headers: {
                "authentication-token": appState.getToken(),
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "authentication-token",
                "Access-Control-Expose-Headers": "authentication-token",
            },
            mode: "cors"
        }).then((response) => {
            // var a = response.body.getReader();
            console.log(response);
            response.blob().then(blob => { console.log(blob); download(blob, "file.csv")})
            // a.read().then(({ done, value }) => {
            //     // saveAsFile(new TextDecoder("utf-8").decode(value), "download.csv")
            // })

        }).catch((err)=>{console.log(err)})
        setLoading(true);
    }

    return { loading, caller };
}

export default DownloadCSV;