import React, { useContext, useEffect, useRef, useState } from 'react';
import CreateGetFetch from "../../ServerCalls/CreateGetFetch";
import { Grid } from "@mui/material"
import SpeciesUpsert from '../../Component/FormContainers/SpeciesUpsert/SpeciesUpsert';
import AppContext from '../../Context/AppContext';

import ReadOnlyTable from "../../Component/DataGrids/AGGrid/ReadOnlyTable";

import CacheManager from '../../Utils/CacheManage';

const SpeciesPage = () => {
    const [data, setData] = useState({"data" : [], "headers":[]})

    const speciesFormRef = useRef(null);
    const appState = useContext(AppContext);
    
    const getObject = CreateGetFetch();
    const cacheManager = new CacheManager();

    const handleCacheCalls = async() => {
        if ( cacheManager.doesExists("speciesMap")){
            setData(cacheManager.getEntry("speciesMap"))
        }else{
            let res = await getObject.caller("/getSpeciesTable");
            let _data = await res.json();
            setData(_data);
            cacheManager.setEntry("speciesMap", _data);
        }
    }

    useEffect(()=>{
        if ( data.data.length === 0 ){
            handleCacheCalls();
        }
    },[data])

    const handleCellDoubleClick = (param) =>{
        let data = param.data;
        speciesFormRef.current.setFormValues(data["Species Code"], data["Species Name"], data["Race Code"], data["Race Name"])
    }

    return (
        <Grid container>
            
            <Grid item sm={12} md={12} lg={appState.getAuthStat() === "Admin"? 6 : 12}>
            <div style={{ boxSizing:"border-box", width:"100%", paddingInlineStart:"0.5rem" }}>
                <div
                    className="ag-theme-quartz" // applying the grid theme
                    style={ { height: 500, width:"100%" } } // the grid will fill the size of the parent container
                >
                    <ReadOnlyTable
                        TableData={ data }
                        config={{
                            onCellDoubleClicked : handleCellDoubleClick
                        }}
                    />

                </div>
            </div>
            </Grid>
            {
                appState.getAuthStat() === "Admin" && (
                <Grid item sm={12} md={12} lg={6}>
                    <SpeciesUpsert ref={speciesFormRef} />
                </Grid>
                )
            }

        </Grid>
        
    )
}

export default SpeciesPage