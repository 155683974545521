const digitsAndSpace = (param) =>{
    const pattern = new RegExp('^[0-9. ]*$')
    return !pattern.test(param);
}

const digits = (param) => {
    const pattern = new RegExp("^[0-9]+$")
    return !pattern.test(param)
}

const alphaAndNum = (param) =>{
    const pattern = new RegExp("^[a-zA-Z0-9. ]*$")
    return !pattern.test(param);
}

const alphaOnly = (param) =>{
    const pattern = new RegExp("^[a-zA-Z ]+$");
    return !pattern.test(param);
}

const speciesCodeValidator = (param) => {
    const speciesCodePattern = /^[0-9]{1,4}-[0-9]{1}$/;
    return !speciesCodePattern.test(param);
}

const dateValidator = (param) => {
    const datePattern = new RegExp('^(0[1-9]|[1-2][0-9]|3[0-1])-([a-zA-Z]{3})-\\d{4}$||^$');
    return !datePattern.test(param);
}

const sexValidator = (param) => {
    let sexVal = param
    if (
        sexVal === "Male" ||
        sexVal === "" ||
        sexVal === "Female" ||
        sexVal === "Unknown"
    ) {
        return true
    }
    return false;
}

const ageValidator = (param) => {
    if ( 
        param === "AD" ||
        param === "IMM" ||
        param === "JUV" ||
        param === "FG" ||
        param === "FW" ||
        param === "1Y" ||
        param === "" ||
        param === " "
    ){
        return true
    }
    const agePattern = new RegExp('^[0-9. ]+$');
    return agePattern.test(param);
}

const ringSizeValidation = (param) => {
    if ( ["A", "AB", "B", "C", "F", "AA", "BC", "G", "K", "L", "M", "YZ", "Z", "NA"].includes(param) ){
        return true;
    }else{
        return false;
    }
}

const weightRingSizeValidation = ( weight, ringSize ) =>{
    let weightRange = {
        "A": {"max": 80, "min": 8},
        "AB": {"max": 150, "min": 22},
        "B": {"max": 243, "min": 17},
        "C": {"max": 1045, "min": 95},
        "F": {"max": 1550, "min": 120},
        "G": {"max": 1380, "min": 420},
        "K": {"max": 3150, "min": 415},
        "L": {"max": 5030, "min": 2120},
        "Z": {"max": 31.9, "min": 6},
    }
    try{
        return ! ( weight >= weightRange[ringSize]["min"] && weight <= weightRange[ringSize]["max"] )
    }
    catch (err){
        return true;
    }
}

const localityValidation = (locality="") =>{
    if(locality.trim().length === 0){
        return false;
    }
    return true;
}

export { digitsAndSpace, digits, alphaAndNum, alphaOnly, speciesCodeValidator, dateValidator, ageValidator, sexValidator, weightRingSizeValidation, ringSizeValidation, localityValidation }