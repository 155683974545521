const mergeAnalysisLoader = async ({ params }) => {
    const BASE_URL_LOC = process.env["REACT_APP_SERVER_LOCATION"];
    let response = await fetch(BASE_URL_LOC + "/allmergedData?limit=0", {
        method: "GET",
        redirect: "follow",
        headers: {
            "authentication-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "authentication-token",
            "Access-Control-Expose-Headers": "authentication-token",
        },
        mode: "cors"
    })
    let data = await response.json();
    return data
}

const mergeConflictLoader = async ({ params }) => {
    const BASE_URL_LOC = process.env["REACT_APP_SERVER_LOCATION"];
    let response = await fetch(BASE_URL_LOC + "/getConflictList?limit=0", {
        method: "GET",
        redirect: "follow",
        headers: {
            "authentication-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "authentication-token",
            "Access-Control-Expose-Headers": "authentication-token",
        },
        mode: "cors"
    })
    let data = await response.json();
    return data
}

const historyDataLoader = async ({ params }) => {
    const BASE_URL_LOC = process.env["REACT_APP_SERVER_LOCATION"];
    let response = await fetch(BASE_URL_LOC + "/getPageHistory?fileName=" + params.fileName, {
        method: "GET",
        redirect: "follow",
        headers: {
            "authentication-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "authentication-token",
            "Access-Control-Expose-Headers": "authentication-token",
        },
        mode: "cors"
    })
    let data = await response.json();
    return data
}

const adminStatsLoader = async () => {
    const BASE_URL_LOC = process.env["REACT_APP_SERVER_LOCATION"];
    let response = await fetch(BASE_URL_LOC + "/getAdminStats", {
        method: "GET",
        redirect: "follow",
        headers: {
            "authentication-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "authentication-token",
            "Access-Control-Expose-Headers": "authentication-token",
        },
        mode: "cors"
    })
    let data = await response.json();
    return data
}

const mergeDataLoader = async ({ params }) => {
    const BASE_URL_LOC = process.env["REACT_APP_SERVER_LOCATION"];


    let response = await fetch(BASE_URL_LOC + "/getConflictDataError" + "?fileName=" + params.page + "&fileYear=" + params.year, {
        method: "GET",
        redirect: "follow",
        headers: {
            "authentication-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "authentication-token",
            "Access-Control-Expose-Headers": "authentication-token",
        },
        mode: "cors"
    })
    let data = await response.json();
    return data

}

const pageDataLoader = async ({ params }) => {
    const BASE_URL_LOC = process.env["REACT_APP_SERVER_LOCATION"];

    let checkResponse = await fetch(BASE_URL_LOC + "/occupyPage?fileName=" + params.page + "&fileYear=" + params.year, {
        method: "GET",
        redirect: "follow",
        headers: {
            "authentication-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "authentication-token",
            "Access-Control-Expose-Headers": "authentication-token",
        },
        mode: "cors"
    })

    if (checkResponse.status === 200) {
        let response = await fetch(BASE_URL_LOC + "/" + params.type + "?fileName=" + params.page + "&fileYear=" + params.year, {
            method: "GET",
            redirect: "follow",
            headers: {
                "authentication-token": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "authentication-token",
                "Access-Control-Expose-Headers": "authentication-token",
            },
            mode: "cors"
        })
        let data = await response.json();
        return data
    } else {

        if (checkResponse.status === 409) {
            throw new Response("Conflict Page Already Under Use", { status: "409" })
        }

        if( checkResponse.status === 410 ){
            throw new Response("Page Restricted By Admin", {  status : "410" })
        }

    }


}


export { mergeAnalysisLoader, historyDataLoader, adminStatsLoader, mergeDataLoader, mergeConflictLoader, pageDataLoader };