const ErrorMerger = (obj1, obj2) => {

    let finalObj = {...obj1};

    Object.keys(obj2).forEach(e=>{
        if (finalObj.hasOwnProperty(e)){
            let temp = obj2[e];
            finalObj[e]['multi'] = true;
            finalObj[e]['obj'] = finalObj[e]['obj'].concat(temp['obj']);
        }else{
            finalObj[e] = obj2[e];
        }
    });
    

    return finalObj;
}

export { ErrorMerger }