import React, { Suspense, useState } from 'react'
import Header from '../SubComponents/Header'
import DynamicBreadCrumbs from '../SubComponents/DynamicBreadCrumbs'
import { Outlet } from 'react-router-dom'
import ErrorBoundary from '../../../Utils/ErrorBoundary'
import MyDrawer from '../SubComponents/Drawer'
import ErrorPage from '../../../Pages/ErrorPage/ErrorPage'
import Loading from '../../../Pages/Loading/LoadingWrapper'

const MainLayout = () => {

  const [open , setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
};


  return (
    <>
    <Suspense fallback={ <Loading /> } >
      <ErrorBoundary fallback={ <ErrorPage /> }>
        <header>
          <MyDrawer open={open} toggleDrawer={toggleDrawer} />
          <Header toggleDrawer={toggleDrawer}/>
          <DynamicBreadCrumbs  />
        </header>
        <main>
          <Outlet />
        </main>
      </ErrorBoundary>
    </Suspense>
    </>
  )
}

export default MainLayout